import React from 'react';
import { userServices } from '../../services/userServices';
import { withRouter, Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg'
import { Toast } from '../../utils/Toast';
class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            emailError: false,
            isSubmitting: false,
        }
    };

    handleInputChange = (e) => {
        this.setState({
            email: e.target.value,
        })
        this.validateInput(e.target.id, e.target.value )
    }

    validateInput = (field, value) => {
        if(field === void 0 && value === void 0){
            let allowSubmit = false;
            let emailError = this.validateInput('email', this.state.email)
            if(emailError){
                allowSubmit = true;
            }
            return allowSubmit;
        }
        if(field === 'email'){
            if(value.trim() === ''){
                this.setState({emailError: "Vui lòng nhập email"});
                return false;
            } else if ((/\S+@\S+\.\S+/.test(value)) === false) {
                this.setState({
                    emailError: 'Email không hợp lệ',
                });
                return false;
            }else {
                this.setState({emailError: ""})
            }
        }
        return true;

    }

    handleSubmit = async (e) => {
        e.preventDefault();
        console.log(this.state.email)
        if(this.validateInput()){
            await this.setState({isSubmitting: true})
            let [success, body] = await userServices.forgotPassword(this.state.email.trim())
            if(success){
                await Toast.Info("Gửi yêu cầu thành công, vui lòng kiểm tra email.")
                this.props.history.push('/login')
            }
        }
        this.setState({isSubmitting: false});
    };

    render(){
        return(
            <div className='login-container'>
                <form onSubmit={this.handleSubmit} className="login-content">
                    <img src={logo} width='327px' height='32px' alt='' />
                    <p className='login'>Quên mật khẩu</p>
                    <p className='forgot-text'>Nhập email của bạn để đặt lại mật khẩu</p>
                    <div className="title-input">Email<span className="ic-start">*</span></div>
                    <input
                        className="field-input"
                        placeholder='Email'
                        id="email"
                        value={this.state.value}
                        onChange={this.handleInputChange}
                    />
                    {this.state.emailError && <div className="message-error">{this.state.emailError}</div>}
                    <button className="login-button" disabled={this.state.isSubmitting}>Quên mật khẩu</button>
                    <Link to='/login' className='cancel'>Huỷ</Link>
                </form>
            </div>

        )
    }
}
export default withRouter(ForgotPassword);