import React, { PureComponent } from 'react';

class IconFilterActive extends PureComponent {
   render() {
      return (
         <svg
            className="icon-filter-active"
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               fillRule="evenodd"
               clipRule="evenodd"
               d="M13 8.5H3v-1h10v1zM10 3H0V2h10v1zM10 14H6v-1h4v1z"
               fill="#FFFFFF"
            />
            <circle
               cx={14}
               cy={2.5}
               r={2}
               fill="#FF7C4B"
            />
         </svg>
      );
   }
}

export default IconFilterActive;