import React, { Fragment, PureComponent } from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import ReactDOM from 'react-dom';
import TogglerContext from './Common/TogglerContext';
import ClonedItem from './Common/ClonedItem';

/**
 * placement: one of:
    'top',
    'right',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
 */

class Dropdown extends PureComponent {
    state = {
        isOpen: false,
    }
    childRef = null;

    addBackDrop = (e) => {
        if (this.childRef) {
            if (this.state.isOpen && !ReactDOM.findDOMNode(this.childRef).contains(e.target)) {
                this.closeDropdown();
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.addBackDrop);
    }

    componentDidMount() {
        window.addEventListener('click', this.addBackDrop);
    }

    setChildRef = (r) => {
        this.childRef = r;
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        }, this.checkVisibled)
    }

    closeDropdown = () => {
        if (this.state.isOpen) {
            this.setState({
                isOpen: false
            }, this.checkVisibled);
        }
    }

    checkVisibled = () => {
        if (this.props.onVisibleChange) {
            this.props.onVisibleChange(this.state.isOpen);
        }
    }

    preventClose = (e) => {
        e.stopPropagation();
    }

    render() {
        const { className, placement = 'bottom', content, children, onVisibleChange, ...props } = this.props;
        return (
            <Fragment>
                {
                React.Children.map(children,
                    child => {
                        return (
                            <ClonedItem
                            {...props}
                            child={child}
                            ref={this.setChildRef}
                            onClick={this.toggle}
                            />
                        )
                    })
                }
                {
                this.state.isOpen && this.childRef &&
                <Popover placement={placement}
                    className={`${className || ''} elite-dropdown`}
                    isOpen={this.state.isOpen}
                    autohide={false}
                    target={this.childRef}
                    hideArrow={true}
                    toggle={this.toggle}
                    onClick={this.preventClose}
                >
                    <TogglerContext.Provider value={{
                        toggle: this.toggle,
                    }}>
                        {
                            React.Children.map(content,
                            child => {
                                return <ClonedItem child={child} onClick={this.preventClose} />
                            })
                        }
                    </TogglerContext.Provider>
                </Popover>
                }
            </Fragment>
        )
    }
}

export default Dropdown;
