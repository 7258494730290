import React, { Fragment, PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Tooltip from '../../utils/Components/Tooltip';
import { constant } from '../../services/constant';
import { saleServices } from '../../services/saleServices';
import { serviceService } from '../../services/serviceServices';
import { Table } from 'reactstrap';
import IconBack from '../Icons/IconBack';
import IconAvatar from '../Icons/IconAvatar';
import IconUpload from '../Icons/IconUpload';
import IconAddLicense from '../Icons/IconAddLicense';
import IconRemoveFile from '../Icons/IconRemoveFile';
import moment from "moment-timezone";
import ModalCommon from '../Common/ModalCommon';
import ClonedItem from '../../utils/Components/Common/ClonedItem';
import IconClose from '../Icons/IconClose';
import SuperTable from '../../utils/Components/SuperTable/SuperTable';
import PaginationModel from '../../utils/Components/SuperTable/Models/PaginationModel';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import { common } from '../../utils/Common';
import UpdateLicens from './UpdateLicense';
import { API_CONST } from '../../Api';
import { Toast } from '../../utils/Toast';
import { ReactComponent as IcLoading } from '../../assets/icons/loading.svg';
import withUserType from '../../HOC/UserTypeHOC';


class CustomerDetail extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            fullname: '',
            services: [],
            contractFiles: [],
            contractFile: '',
            contractFileNames: [],
            filesNeedUpload: [],
            contractFileURL: '',
            openConfirmation: false,
            submitting: false,
            columns: [],
            licensesData: [],
            pagination: new PaginationModel(),
            addLicenseModal: false,
        }
    }

    _isMounted = false;

    componentWillMount() {
        this._isMounted = false;
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.getUserInfoByEmail();
        this.getAttachmentFile();
        this.prepareColumn();
        this.loadData();
    }

    getUserInfoByEmail = async () => {
        let email = this.props.match.params[constant.EMAIL_PARAM];
        let [success, body] = await saleServices.getUserInfoByEmail(email);
        if (success && body.data) {
            this.setState({
                data: body.data,
                fullname: body.data.full_name,
            })
        }
    }

    getAttachmentFile = async () => {
        let data = {
            'author_email': this.props.match.params[constant.EMAIL_PARAM],
            'file_type': 'CONTRACT',
        }
        let [success, body] = await saleServices.getContractFiles(data);
        if (success && body.data) {
            this.setState({
                contractFiles: body.data.content,
            })
        }
    }

    handleUploadContract = async (e) => {
        let files = [...this.state.filesNeedUpload, ...e.target.files]
        let names = [];
        for (let i of files) {
            names.push({
                'name': i.name,
                'key': common.generateId(15),
                'file': i,
            })
        }
        this.setState({
            contractFileNames: names,
            filesNeedUpload: files,
        })
    }

    handleInvokeAPIUpload = async () => {
        let files = this.state.filesNeedUpload;
        let formData = new FormData();
        for (let i of files) {
            formData.append('files', i);
        }
        formData.append('customer_email', this.props.match.params[constant.EMAIL_PARAM]);
        let [success, body] = await saleServices.uploadContractFile(formData);
        if (success) {
            Toast.Info('Upload file thành công !!!');
            this.getAttachmentFile();
            this.setState({
                contractFileNames: [],
                filesNeedUpload: [],
            })
        }
    }

    removeFile = (file) => {
        let names = this.state.contractFileNames;
        let files = this.state.filesNeedUpload;
        if (names) {
            names = names.filter(i => i.key !== file.key);
        }
        let newFileList = Array.from(files);
        newFileList = newFileList.filter(item => !_isEqual(item, file.file));
        this.setState({
            contractFileNames: names,
            filesNeedUpload: newFileList,
        });
    }

    handeDeleteFile = async (data) => {
        let [success, body] = await saleServices.deleteContractFile(data.uid);
        if (success) {
            Toast.Info(`Đã xoá file ${data.digest}`);
            this.getAttachmentFile();
        }
    }

    handeChangeName = (e) => {
        this.toggleConfirmModal(e);
    }

    toggleConfirmModal = (e) => {
        this.setState({
            openConfirmation: !this.state.openConfirmation,
        });
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    handleChangeName = async (name) => {
        let data = this.state.data;
        let formData = new FormData();
        if (data) {
            formData.append('full_name', name);
            formData.append('salesclerk_email', data.salesclerk_email);
            let [success, body] = await saleServices.updateInfoUser(data.email, formData);
            if (success) {
                Toast.Info('Cập nhật thông tin thành công');
                this.toggleConfirmModal();
                this.getUserInfoByEmail();
            }
        }
    }

    dateFormatter = (cell, row, rowIndex) => {
        return common.convertToDateTimeForSale(row.created_at);
    }

    expireDateFormatter = (cell, row, rowIndex) => {
        return common.convertDayMonthYear(row.due_date);
    }

    numbericFormatter = (cell, row, rowIndex) => {
        return rowIndex + 1;
    }

    trialFormatter = (cell, row, rowIndex) => {
        if(cell) {
            return <span>True</span>
        }
        return <span>False</span>
    }
    prepareColumn = () => {
        this.setState({
            columns: [{
                header: 'STT',
                cell: 'stt',
                formatter: this.numbericFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Tên licenses',
                cell: 'license_name',
                formatter: this.nameFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Trạng thái',
                cell: 'status',
                formatter: this.statusFormatter,
                headerClassName: 'content-no-wrap',
            },
            {
                header: 'Trial',
                cell: 'trial',
                formatter: this.trialFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Ngày active',
                cell: 'start_date',
                formatter: this.dateFormatter,
                headerClassName: 'content-no-wrap',
                className: 'content-min-width',
            }, {
                header: 'Ngày hết hạn',
                cell: 'due_date',
                formatter: this.expireDateFormatter,
                headerClassName: 'content-no-wrap',
                className: 'content-min-width',
            }]
        });
    }

    statusFormatter = (cell, row, rowIndex) => {
        if (row.license.status === 'Expired') {
            return <div className="red-status">Expired</div>;
        }
        else if (row.license.status === 'USED') {
            return <div className="green-status">Used</div>;
        }
        return row.license.status;
    }

    loadData = async (pagination) => {
        if (!this._isMounted) {
            return;
        }

        let newPagination = _cloneDeep(this.state.pagination);
        if (pagination) {
            newPagination = pagination;
        }
        await this.setState({ isLoading: true });
        let email = this.props.match.params[constant.EMAIL_PARAM];
        let requestData = {
            "page": newPagination.pageNo,
            "page_size":  newPagination.pageSize,
            'author_email': email,
        }
        let [isSuccess, response] = await serviceService.getListServiceOfUser(requestData);
        if (this._isMounted) {
            let data = [];
            if (isSuccess) {
                if (response && response.data) {
                    data = response.data.content || [];
                    newPagination = new PaginationModel(response.data.pageSize, response.data.currentPage, response.data.totalRows);
                }
            }
            if (!_isEqual(data, this.state.data)) {
                await setTimeout(() => {
                    this.setState({
                    licensesData: data,
                    pagination: newPagination,
                    isLoading: false,
                    });
                }, 300);
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    handlePaginate = (newPagination) => {
        if (newPagination instanceof PaginationModel) {
            let pagination = _cloneDeep(newPagination);
            this.setState({
                pagination: pagination,
            });
            this.loadData(pagination);
        }
    }

    toggleConformAddLicenseModal = () => {
        this.setState({addLicenseModal: !this.state.addLicenseModal})
    }

    renderAddLicense = () => {
        if (this.props.userType !== "REPORTER"){
            if (this.state.data.status !== "LOCKED"){
                return <div className='add-license' onClick={this.toggleConformAddLicenseModal}><IconAddLicense />Add Licenses</div>
            }
        }

    }

    render() {
        const { data, services } = this.state;
        const { children } = this.props;

        return (
            <Fragment>
                {
                    this.state.isLoading &&
                    <div className="content-loading">
                        <IcLoading />
                    </div>
                }
                { data && services &&
                <div className="customer-details">
                    <div className='icon-back'>
                        <Link to={constant.ROUTE_CUSTOMER_LIST}><IconBack/></Link>
                    </div>
                    <div className="customer-detail-container">
                        <div className='update-customer'>
                            <div className='avatar'><IconAvatar/></div>
                            <Table className='table-customer'>
                                <thead>
                                    <tr>
                                        <th>{data.full_name}</th>
                                        <th>Ngày tạo</th>
                                        <th>Người tạo</th>
                                        <th>Sale quản lý</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{data.email}</td>
                                        <td>{data.joined_at && moment(data.joined_at).format('DD/MM/YYYY')}</td>
                                        <td>{data.salesclerk_email}</td>
                                        <td>{data.salesclerk_email}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className='edit-name' onClick={this.handeChangeName}>Edit Name</div>
                            <Fragment>
                                {
                                React.Children.map(children,
                                    child => {
                                        return (
                                            <ClonedItem
                                            child={child}
                                            onClick={this.toggleConfirmModal}
                                            />
                                        )
                                    }
                                )
                                }
                                <ModalCommon isOpen={this.state.openConfirmation} toggle={this.toggleConfirmModal}>
                                <div className="common-modal-header">
                                    <label>Đổi tên</label>
                                    <div className="circle-button" onClick={this.toggleConfirmModal}><IconClose /></div>
                                </div>
                                <div className="common-modal-body">
                                    <input
                                        className="field-input"
                                        name="fullname"
                                        value={this.state.fullname}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="modal-actions">
                                        <button className="btn-cancel" onClick={this.toggleConfirmModal}>Đóng</button>
                                        <button className="btn-active bg-orange" onClick={() => this.handleChangeName(this.state.fullname)} disabled={this.state.submitting}>Đồng ý</button>
                                    </div>
                                </div>
                                </ModalCommon>
                            </Fragment>
                        </div>
                        <div className='contract-file'>
                            <div className='title'>Hợp đồng</div>
                            <div className='line'></div>
                            {
                                this.state.contractFileNames.length !== 0 ?
                                <div className='selected-file-container'>
                                    <div className='selected-list'>
                                    {
                                        this.state.contractFileNames.map(file => {
                                            return (
                                                <div className='upload-file'>
                                                    <div className='file-name'>{file.name}</div>
                                                    <div className='d-flex align-items-center'>
                                                        <div onClick={() => this.removeFile(file)}><IconRemoveFile/></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                    {/* <IconUpload onClick={this.handleInvokeAPIUpload} /> */}
                                    <div className='d-flex align-items-center ml-auto'>
                                        <input
                                            type="file"
                                            className="file-input"
                                            id="upload-contract"
                                            aria-describedby="inputGroupFileAddon01"
                                            onChange={this.handleUploadContract}
                                            multiple
                                            accept=".doc, .docx, application/pdf, image/png, image/jpeg"
                                        />
                                        <label htmlFor="upload-contract">
                                            <IconUpload />
                                            <span className='upload-text'>Tải file</span>
                                        </label>
                                        <div onClick={this.handleInvokeAPIUpload} className='button-upload'>Upload</div>
                                    </div>
                                </div>
                                :
                                <div className="upload-action">
                                    <input
                                        type="file"
                                        className="file-input"
                                        id="upload-contract"
                                        aria-describedby="inputGroupFileAddon01"
                                        onChange={this.handleUploadContract}
                                        multiple
                                        accept=".doc, .docx, application/pdf, image/png, image/jpeg"
                                    />
                                    <label htmlFor="upload-contract">
                                        <IconUpload />
                                        <span className='upload-text'>Tải file</span>
                                    </label>
                                </div>
                            }
                        </div>
                        {
                            this.state.contractFiles.length !== 0 &&
                            <div className='selected-file'>
                            {
                                this.state.contractFiles.map(item => {
                                    return (
                                        <div className='item-file'>
                                            <a target='_blank' rel='noopener noreferrer' href={`${API_CONST.API_URL}${item.file_path}`}>
                                                <div className='file-name'>{item.file_name}</div>
                                            </a>
                                            <div onClick={() => this.handeDeleteFile(item)}>
                                                <Tooltip title="Xoá file">
                                                    <div className="btn-common-student bg-red">
                                                        <IconRemoveFile/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        }

                        <div className='license-title'>
                            <div className='title'>Lisenses</div>
                            {this.renderAddLicense()}

                        </div>
                        <div className="instructor-body license-list">
                            <div className="instructor-wrap">
                                <SuperTable
                                        rowKey="uid"
                                        unit="Danh sách Licenses"
                                        data={this.state.licensesData}
                                        columns={this.state.columns}
                                        pagination={this.state.pagination}
                                        onPaginate={this.handlePaginate}
                                        // rowsActive={this.state.selectedData}
                                />
                            </div>
                        </div>
                        <UpdateLicens
                            user={this.props.match.params[constant.EMAIL_PARAM]}
                            isOpen={this.state.addLicenseModal}
                            handleToggle={this.toggleConformAddLicenseModal}
                            reloadData={this.loadData}
                        />
                    </div>
                </div>
                }
            </Fragment>
        )
    }
}

export default withRouter(withUserType(CustomerDetail));