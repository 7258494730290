import React, { PureComponent } from 'react';
import _ from 'lodash';
import SortObjModel from './Models/SortObjModel';

class HeaderColumn extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            sortType: '',
        }
    }

    componentDidMount() {
        const { sortObj, name } = this.props;
        if (sortObj && sortObj instanceof SortObjModel) {
            if (sortObj.orderBy === name) {
                this.setState({
                sortType: sortObj.sortType,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.sortObj, this.props.sortObj) && this.props.sortObj instanceof SortObjModel) {
            if (this.props.sortObj.orderBy === this.props.name) {
                this.setState({
                sortType: this.props.sortObj.sortType,
                });
            } else {
                this.setState({
                sortType: '',
                });
            }
        }
    }

    getNextSortType = (currentType) => {
        if (currentType === 'ASC') {
            return 'DESC';
        } else if (currentType === 'DESC') {
            return '';
        }
        return 'ASC';
    }

    sortColumn = () => {
        if (this.props.sort) {
            const { name } = this.props;
            const { sortType } = this.state;
            let nextSortType = this.getNextSortType(sortType);
            console.log(nextSortType, name)
            let sortObj = new SortObjModel(nextSortType ? name : '', nextSortType);
            this.setState({
                sortType: sortObj.sortType,
            });
            this.props.onSort(sortObj);
        }
    }

    render() {
        const { header, sort } = this.props;
        const { sortType } = this.state;
        return (
            <span className="header-column-data disable-select">
                <span className={`${sort ? 'label-sort-col' : 'header-label'} ${sortType === 'DESC' ? 'sort-col-desc' : `${sortType === 'ASC' ? 'sort-col-asc' : ''}`}`}
                onClick={this.sortColumn}
                >
                {header}
                {
                    sort &&
                    <i className="ic-12 ic-sort-super-col" />
                }
                </span>
            </span>
        );
    }
}

export default HeaderColumn;