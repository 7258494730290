import React, { PureComponent } from 'react';

class IconNotFound extends PureComponent {
    render() {
        return (
            <svg
                width={49}
                height={48}
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.6733 37.3552C31.0707 40.2606 26.4885 42 21.5 42C9.90202 42 0.5 32.598 0.5 21C0.5 9.40202 9.90202 0 21.5 0C33.098 0 42.5 9.40202 42.5 21C42.5 25.9885 40.7606 30.5707 37.8553 34.1732L47.841 44.1589C48.7197 45.0376 48.7197 46.4622 47.841 47.3409C46.9623 48.2196 45.5377 48.2196 44.659 47.3409L34.6733 37.3552ZM38 21C38 30.1127 30.6127 37.5 21.5 37.5C12.3873 37.5 5 30.1127 5 21C5 11.8873 12.3873 4.5 21.5 4.5C30.6127 4.5 38 11.8873 38 21Z"
                    fill="#DBDBDB"
                />
                </g>
            </svg>
        );
    }
}

export default IconNotFound;