import React, { PureComponent } from 'react';

class IconLicense extends PureComponent {
    render() {
        return (
            <svg 
                className="ic-old-students"
                width={16} 
                height={16} 
                viewBox="0 0 16 16" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0)" fill="#AAA9B7">
                <path d="M7.35 4.033L5.333 0H.667l2.866 5.733a6.636 6.636 0 013.817-1.7zM8.65 4.033L10.667 0h4.666l-2.866 5.733a6.636 6.636 0 00-3.817-1.7z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.037 6.232a5.333 5.333 0 115.926 8.87 5.333 5.333 0 01-5.926-8.87zM8 12.54l1.848.972-.354-2.058 1.496-1.458-2.066-.3L8 7.822l-.921 1.873-2.066.3 1.493 1.458-.354 2.058L8 12.539z"
                />
                </g>
                <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H16V16H0z" />
                </clipPath>
                </defs>
            </svg>
        );
    }
}

export default IconLicense;