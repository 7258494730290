import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { constant } from "./services/constant";
import { Toast } from "./utils/Toast";
import DefaultLayout from "./view/DefaultLayout/DefaultLayout";
import ForgotPassword from "./view/Login/ForgotPassword";
import Login from "./view/Login/Login";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <HashRouter>
      {Toast.renderContainer()}
      <Switch>
        <Redirect exact from="/" to={constant.ROUTE_CUSTOMER_LIST} />
        <Route path={constant.ROUTE_LOGIN} component={Login}/>
        {/* <Route path={constant.FORGOT_PASSWORD} component={ForgotPassword}/> */}
        <Route path="/" component={DefaultLayout}/>
      </Switch>
    </HashRouter>
  );
}

export default App;
