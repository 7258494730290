import React, { PureComponent } from 'react';

class IconUserList extends PureComponent {
    render() {
        return (
            <svg
                className="ic-student-list"
                width={16}
                height={16} 
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx={8} cy={3.5} r={3} fill="#AAA9B7" stroke="#AAA9B7" />
                <rect
                    x={0.5}
                    y={9.5}
                    width={15}
                    height={6}
                    rx={3}
                    fill="#AAA9B7"
                    stroke="#AAA9B7"
                />
            </svg>
        );
    }
}

export default IconUserList;