import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '../../utils/Components/Tooltip';
import LockCustomer from '../Customer/LockCustomer';
import UnLockCustomer from '../Customer/UnLockCustomer';
import TransferCustomer from '../Customer/TransferCustomer';
import IconCancel from '../Icons/IconCancel';
import IconTransfer from '../Icons/IconTransfer';
import IconLock from '../Icons/IconLock';

export default class ActionGroup extends PureComponent {

    unLockCustomer = () => {
        return (
            <UnLockCustomer
            selectedData={this.props.selectedData}
            courseId={this.props.courseId}
            onSuccess={this.props.onSuccess}
            >
            <Tooltip title="Unlock">
                <div className="btn-common-student bg-red">
                    <IconCancel />
                </div>
            </Tooltip>
            </UnLockCustomer>
        )
    }

    lockCustomer = (data, role) => {
        let isCustomer = data.is_customer;
        return (
            <div>
                {
                    isCustomer && role !== 'SALE_AREA' &&
                    this.transferCustomer(data)
                }
                <LockCustomer
                    selectedData={this.props.selectedData}
                    onSuccess={this.props.onSuccess}
                >
                    <Tooltip title="Lock">
                        <div className="btn-common-student bg-red">
                            <IconLock />
                        </div>
                    </Tooltip>
                </LockCustomer>
            </div>
        )
    }

    transferCustomer = (data) => {
        return (
            <TransferCustomer
                selectedData={this.props.selectedData}
                onSuccess={this.props.onSuccess}
                selectedCustomer={data}
                sales={this.props.sales}
            >
                <Tooltip title="Transfer">
                    <div className="btn-common-student bg-red">
                        <IconTransfer />
                    </div>
                </Tooltip>
            </TransferCustomer>
        )
    }

    renderIcon = () => {
        let result;
        if(this.props && this.props.row){
            let status = this.props.row.status;
            if(status === "AVAILABLE"){
                result = this.lockCustomer(this.props.row, this.props.role);
            } else if(status === "LOCKED"){
                result = this.unLockCustomer();
            }
        }
        return result;
    }

    render() {
        return  (
            <div className="container-btn">
                {this.renderIcon()}
            </div>
        )
    }
}
