import React, { Fragment, PureComponent } from 'react';
import { common } from '../../utils/Common';
import HeaderSelectAll from '../Common/HeaderSelectAll';
import _cloneDeep from 'lodash/cloneDeep';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import ActionGroup from './ActionGroup';
import { saleServices } from '../../services/saleServices';
import SuperTable from '../../utils/Components/SuperTable/SuperTable';
import PaginationModel from '../../utils/Components/SuperTable/Models/PaginationModel';
import CheckboxSelectRow from '../Common/CheckboxSelectRow';
import Tooltip from '../../utils/Components/Tooltip';
import { ReactComponent as IcLoading } from '../../assets/icons/loading.svg';
import SearchBox from '../Common/SearchBox';
import LeftMenu from '../Common/LeftMenu';
import UnenrollBtn from '../Sale/UnenrollBtn';
import IconFilter from '../Icons/IconFilter';
import IconAdd from '../Icons/IconAdd';
import IconNotFound from '../Icons/IconNotFound';
import IconSale from '../Icons/IconSale';
import { constant } from '../../services/constant';
import CreateSale from './CreateSale';
import ModalCommon from '../Common/ModalCommon';
import AddItem from '../Common/AddItem';
import Export from './Export';
import withUserType from '../../HOC/UserTypeHOC';

class Sales extends PureComponent {
    state = {
        data: [],
        columns: [],
        selectedData: [],
        search: '',
        pagination: new PaginationModel(),
        isOpen: false,
        startCreateOpen: false,
    }

    _isMounted = false;

    componentWillMount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.prepareColumn();
        this.loadData();
    }


    dateFormatter = (cell, row, rowIndex) => {
        return common.convertToDateTimeForSale(row.joined_at);
    }

    statusFormatter = (cell, row, rowIndex) => {
        if (row.status === 'LOCKED') {
            return <div className="purple-status">Locked</div>;
        }
        else if (row.status === 'AVAILABLE') {
            return <div className="green-status">Available</div>;
        }
        return row.status;
    }

    emailFormatter = (cell, row, rowIndex) => {
        return <div>{row.email}</div>
            // <Link to={`customers/${row.email}`}>{row.email}</Link>
    }

    // nameFormatter = (cell, row, rowIndex) => {
    //     return `${row.full_name}`.trim() ? row.full_name : <div>full_name</div>;
    // }

    onUnenrollSuccess = () => {
        let pagination = new PaginationModel(this.state.pagination.pageSize, 1);
        this.loadData(pagination);
        this.setState({
            selectedData: [],
        }, this.prepareColumn);
    }

    actionFormatter = (cell, row, rowIndex) => {
        return <ActionGroup
            row={row}
            selectedData={[row.email]}
            courseId={this.props.match.params.courseId}
            onSuccess={this.onUnenrollSuccess}
            match={this.props.match}
        />
    }

    checkSelectAll = () => {
        const { selectedData, data } = this.state;
        console.log('DATA ', data)

        if (selectedData.length !== 0) {
            for (const item of data) {
                if (!_includes(selectedData, item.email)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    onCheckAll = (checked) => {
        let selectedData = _cloneDeep(this.state.selectedData);
        console.log(selectedData)
        for (const item of this.state.data) {
            if (checked && !_includes(selectedData, item.email)) {
                selectedData.push(item.email);
            } else if (!checked && _includes(selectedData, item.email)) {
                selectedData = selectedData.filter(i => i !== item.email);
            }
        }
        this.setState({
            selectedData: selectedData,
        }, this.prepareColumn);
    }

    checkARowSelected = (email) => {
        return this.state.selectedData.some(i => i === email);
    }

    onCheckARow = (email, checked) => {
        let selectedData = _cloneDeep(this.state.selectedData);
        if (checked && !selectedData.some(i => i === email)) {
            selectedData.push(email);
        } else if (!checked) {
            selectedData = selectedData.filter(i => i !== email);
        }
        this.setState({
            selectedData: selectedData,
        }, this.prepareColumn);
    }

    nameFormatter = (cell, row, rowIndex) => {
        return (
            <CheckboxSelectRow
                name={row.email}
                label={row.full_name}
                checked={this.checkARowSelected(row.email)}
                onChange={this.onCheckARow}
            />
        );
    }

    checkPartialSelection = () => {
        const { selectedData, data } = this.state;
        if (selectedData.length !== 0) {
            for (const item of data) {
                if (_includes(selectedData, item.email)) {
                    return true;
                }
            }
        }
        return false;
    }

    handlePaginate = (newPagination) => {
        if (newPagination instanceof PaginationModel) {
            let pagination = _cloneDeep(newPagination);
            this.setState({
                pagination: pagination,
            });
            this.loadData(pagination);
        }
    }

    numberFormatter = (cell, row, rowIndex) => {
        let pageNo = this.state.pagination.pageNo;
        let index = rowIndex;
        if (pageNo !== 1) {
            index = (rowIndex + 1) + constant.DEFAULT_PAGE_SIZE * (pageNo - 1);
        } else {
            index = index + 1;
        }
        return index;
    }

    prepareColumn = () => {
        let columns = [];
        if (this.props.userType === 'REPORTER'){ //la reporter se ko co action
            columns = [{
                header: 'STT',
                cell: '',
                formatter: this.numberFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Tên',
                cell: 'full_name',
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Email',
                cell: 'email',
                formatter: this.emailFormatter,
                headerClassName: 'content-no-wrap',
                className: 'content-min-width',
            }, {
                header: 'Trạng thái',
                cell: 'status',
                formatter: this.statusFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Ngày tạo',
                cell: 'joined_at',
                formatter: this.dateFormatter,
                headerClassName: 'content-no-wrap',
            }]
        } else {
            columns = [{
                header: 'STT',
                cell: '',
                formatter: this.numberFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Tên',
                cell: 'full_name',
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Email',
                cell: 'email',
                formatter: this.emailFormatter,
                headerClassName: 'content-no-wrap',
                className: 'content-min-width',
            }, {
                header: 'Trạng thái',
                cell: 'status',
                formatter: this.statusFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Ngày tạo',
                cell: 'joined_at',
                formatter: this.dateFormatter,
                headerClassName: 'content-no-wrap',
                className: 'content-min-width',
            }, {
                header: 'Hành động',
                headerClassName: 'header-action',
                className: 'column-action custom-col-action',
                formatter: this.actionFormatter,
            }]
        }
        this.setState({
            columns: columns,
        });
    }

    loadData = async (pagination, searchValue) => {
        if (!this._isMounted) {
            return;
        }
        let search = this.state.search;
        if (searchValue !== void 0 && searchValue !== null) {
            search = searchValue;
        }
        let newPagination = _cloneDeep(this.state.pagination);
        if (pagination) {
            newPagination = pagination;
        }
        await this.setState({ isLoading: true });
        let body = {
            "page": newPagination.pageNo,
            "page_size":  newPagination.pageSize,
            "order_by": "joined_at", // String - optional
            "sort_type": "DESC", // String - optional
            "search": searchValue,
            "is_customer": false, //--boolean - default: false - optional
            "joined_date": "" //--String - format: YYYY-MM-DD - optional
        }
        // if (this.props.userType !== 'REPORTER'){
        //     body.manager_email = this.props.email; //--String - optional
        // }
        const [isSuccess, response] = await saleServices.getListSales(body);
        console.log(response);
        if (this._isMounted) {
            let data = [];
            if (isSuccess) {
                if (response && response.data) {
                    data = response.data.content || [];
                    newPagination = new PaginationModel(response.data.pageSize, response.data.currentPage, response.data.totalRows);
                }
            }
            if (!_isEqual(data, this.state.data)) {
                await setTimeout(() => {
                    this.setState({
                    data: data,
                    pagination: newPagination,
                    search: searchValue || '',
                    isLoading: false,
                    });
                }, 300);
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    openRegister = () => {

    }

    deselectAll = () => {
        this.onCheckAll(false);
    }

    handleSearch = (value) => {
        let pagination = new PaginationModel(this.state.pagination.pageSize, 1);
        this.loadData(pagination, value)
    }

    removeFilter = () => {
        this.setState({
            search: '',
        }, () => {
            let pagination = new PaginationModel(this.state.pagination.pageSize, 1);
            this.loadData(pagination, '')
        })
    }

    handleToggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    startCreateOpen = () => {
        this.setState({
            startCreateOpen: !this.state.startCreateOpen
        })
    }

    render() {
        return (
            <Fragment>
                {
                    this.state.isLoading &&
                    <div className="content-loading">
                        <IcLoading />
                    </div>
                }
                <div className="instructor-header">
                    <div className="left-group">
                        <LeftMenu />
                        <span className="page-name disable-select">Danh sách nhân viên sales</span>
                    </div>
                    <div className="center-container">
                        <SearchBox
                            value={this.state.search}
                            placeholder="Tên nhân viên hoặc email"
                            onSearch={this.handleSearch}
                        />
                    </div>
                    <div className="right-group">
                        <Export data={this.state.data} />
                        {/* <Tooltip title="Filter">
                            <div className="header-button"><IconEmail /></div>
                        </Tooltip> */}
                        {/* <div className="header-button"><IconFilter /></div> */}
                        {
                            this.props.userType !== "REPORTER" &&
                                <AddItem
                                    isOpen={this.state.isOpen}
                                    handleToggle={this.handleToggle}
                                    reloadData={this.loadData}
                                    match={this.props.match}
                                />
                        }
                    </div>
                </div>
                <div className="instructor-body">
                    <div className="instructor-wrap">
                        {
                            this.state.data.length !== 0 &&
                            <SuperTable
                                rowKey="email"
                                unit="Danh sách Sales"
                                data={this.state.data}
                                columns={this.state.columns}
                                pagination={this.state.pagination}
                                onPaginate={this.handlePaginate}
                                rowsActive={this.state.selectedData}
                            />
                        }
                        {
                            !this.state.isLoading &&
                            this.state.data.length === 0 &&
                            <div className="container-empty-student">
                                {
                                this.state.search.trim() !== '' ? (
                                    <Fragment>
                                        <IconNotFound />
                                        <div className="label-emtpy-student">
                                            {`Không tìm thấy kết quả nào \nvới từ khoá “${this.state.search}”`}
                                        </div>
                                        <div className="remove-filter" onClick={this.removeFilter}>Xoá bộ lọc</div>
                                    </Fragment>
                                ) : (
                                        <Fragment>
                                            <IconSale />
                                            <div className="label-emtpy-student">Chưa có nhân viên sales nào</div>
                                            {
                                                    this.props.userType !== "REPORTER" &&
                                                    <div className="btn-begin-enroll" onClick={this.startCreateOpen}>
                                                        <IconAdd />
                                                        <div>Bắt đầu thêm nhân viên</div>
                                                    </div>
                                            }
                                            <ModalCommon isOpen={this.state.startCreateOpen} toggle={this.startCreateOpen}>
                                                <CreateSale
                                                    handleToggle={this.startCreateOpen}
                                                    reloadData={this.loadData}
                                                />
                                            </ModalCommon>
                                        </Fragment>
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withUserType(Sales);