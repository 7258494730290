import React, { PureComponent } from 'react';

class IconFilter extends PureComponent {
    render() {
        return (
            <svg 
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M3 8h10M0 2.5h16M6 13.5h4" stroke="#fff" strokeWidth={1.5} />
            </svg>
        );
    }
}

export default IconFilter;