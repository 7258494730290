import React, { PureComponent, Fragment } from 'react';
import _get from 'lodash/get';

class SuperRowColumn extends PureComponent {

    handleGetData = (rowData, col) => {
        return _get(rowData, col.cell) ? _get(rowData, col.cell) : '';
    }

    render() {
        const { col, rowData, rowIndex } = this.props;
        return (
            <Fragment>
            {col.formatter ? col.formatter(this.handleGetData(rowData, col), rowData, rowIndex) : (col.cell ? this.handleGetData(rowData, col) || '' : '')}
            </Fragment>
        )
    }
}

export default SuperRowColumn;