import { Toast } from '../utils/Toast';
import { API_CONST } from "../Api";

const getListServiceOfUser = async (data) => {
    let response;
    let token = localStorage.getItem('token');
    let url = API_CONST.GET_LIST_SERVICES;
    let options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body]
    } catch (error) {
        return [false, null]
    }

};

const getLicenseList = async () => {
    let response;
    let token = localStorage.getItem('token');
    let url = API_CONST.GET_LICENSE_LIST;
    let options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body]
    } catch (error) {
        return [false, null]
    }
};

const extendLicense = async (data) => {
    let response;
    let token = localStorage.getItem('token');
    let url = API_CONST.EXTEND_LICENSE;
    let options = {
        method: 'POST',
        body: data,
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body]
    } catch (error) {
        return [false, null]
    }

};

const getTemplateFile = async () => {
    let response;
    let token = localStorage.getItem('token');
    let url = API_CONST.GET_TEMPLATE_FILE;
    let options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body]
    } catch (error) {
        return [false, null]
    }
};


export const serviceService = {
    getListServiceOfUser,
    getLicenseList,
    extendLicense,
    getTemplateFile,
}