import { Toast } from '../utils/Toast';
import { API_CONST } from "../Api";

const login = async (data) => {
    let response;
    let options = {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json"}
    }
    let url = API_CONST.LOGIN_USER;
    try{
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body]
    }
    catch(error){
        return [false,null]
    }
};

const getUserProfile = async () => {
    let response;
    let url = API_CONST.GET_USER_PROFILE;
    let token = localStorage.getItem('token');
    let options = {
        headers: {
            "Authorization": "Bearer " + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body]
    } catch(error) {
        return [false, null];
    }
};

const changePassword = async (data) => {
    let response;
    let url = API_CONST.CHANGE_PASSWORD;
    let token = localStorage.getItem('token');
    let options = {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];

    } catch(error) {
        console.log(error)
        return [false, null];
    }
};

const forgotPassword = async (email) => {
    let response;
    let options = {
        method: 'PUT',
        headers: { "Content-Type": "application/json"}
    }
    let url = API_CONST.FORGOT_PASSWORD(email);
    try{
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    }
    catch(error){
        return [false, null];
    }
};

const logout = async () => {
    let response;
    let url = API_CONST.LOGOUT_USER;
    let token = localStorage.getItem('token');
    let options = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    } catch(error) {
        return [false, null]
    }
}

export const userServices = {
    login,
    getUserProfile,
    changePassword,
    forgotPassword,
    logout,
}