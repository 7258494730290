import React, { Fragment, PureComponent } from 'react';
import SingleCheckbox from '../../utils/Components/SingleCheckbox';
import IconDown from '../Icons/IconDown';

class HeaderSelectAll extends PureComponent {
    render() {
        const { label, isPartial, checked } = this.props;
        return (
            <SingleCheckbox
                className="header-select-all"
                label={(
                <Fragment>
                    <IconDown /><span className="label-select-all">{label}</span>
                </Fragment>
                )}
                checked={checked}
                isPartial={isPartial}
                onChange={this.props.onChange}
            />
        );
    }
}

export default HeaderSelectAll;