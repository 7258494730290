import React, { PureComponent } from 'react';

class IconTemplateFile extends PureComponent {
    render() {
        return (
            <svg 
                width={16} 
                height={16} 
                viewBox="0 0 16 16" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.5 2A1.5 1.5 0 013 .5h6.172a1.5 1.5 0 011.06.44l3.829 3.828a1.5 1.5 0 01.439 1.06V14a1.5 1.5 0 01-1.5 1.5H3A1.5 1.5 0 011.5 14V2z"
                    fill="#AAA9B7"
                    stroke="#AAA9B7"
                />
                <path
                    d="M12.317 5.317L9.683 2.683A.4.4 0 009 2.966V5.6c0 .22.18.4.4.4h2.634a.4.4 0 00.283-.683z"
                    fill="#fff"
                />
            </svg>
        );
    }
}

export default IconTemplateFile;