import React, { PureComponent } from 'react';

class IconTransfer extends PureComponent {
    render() {
        return (
            <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
            >
                <g clipPath="url(#clip0)">
                <path d="M14.5 16v-2a6.5 6.5 0 00-11.624-4" stroke="#65627A" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.47 10.711l1.812 1.79H1v1h6.282L5.47 15.288l.702.712 2.679-2.645a.5.5 0 000-.712L6.172 10l-.702.711z"
                    fill="#65627A"
                />
                <circle cx={8} cy={3} r={2.5} stroke="#65627A" />
                </g>
                <defs>
                <clipPath id="clip0">
                    <path fill="#fff" d="M0 0H16V16H0z" />
                </clipPath>
                </defs>
            </svg>
        )
    }
}

export default IconTransfer;