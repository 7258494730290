import React, { PureComponent } from 'react'
import { constant } from '../../services/constant';

export default class IconCancel extends PureComponent {
    render() {
        const { size = 16 } = this.props;
        return (
            <svg
                className="ic-cancel"
                width={size ? size : 16}
                height={size ? size : 16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="2.5" y="6.5" width="11" height="9" rx="1.5" stroke="#797979"/>
                <path 
                    d="M5.5 3.5V6H4.5V3.5C4.5 1.567 6.067 0 8 0C9.76324 0 11.2219 1.30385 11.4646 3H10.45C10.2184 1.85888 9.20948 1 8 1C6.61929 1 5.5 2.11929 5.5 3.5Z" 
                    fill="#797979"
                />
            </svg>
        )
    }
}
