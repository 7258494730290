import React, { Fragment, PureComponent } from 'react';
import { Link, NavLink, Route, Switch, withRouter } from 'react-router-dom';
import { constant } from '../../services/constant';
import { userServices } from '../../services/userServices';
import LeftMenu from '../Common/LeftMenu';
import ChangePassword from './ChangePassword';
import UserInfo from './UserInfo';


class Avatar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            
        }
    }

    render() {
        const {data} = this.state;
        return (
            <Fragment>
                <div className="instructor-header">
                    <div className="left-group">
                        <LeftMenu />
                        <span className="page-name disable-select">Tài khoản</span>
                    </div>
                </div>
                <div className='profile'>
                        <div className='content-left'>
                            <div><NavLink className='tab' to={constant.ROUTE_PROFILE_INFO}>Thông tin cá nhân</NavLink></div>
                            <div><NavLink className='tab' to={constant.ROUTE_PROFILE_PASSWORD}>Mật khẩu</NavLink></div>
                        </div>
                        <div className='content-right'>
                            <Switch>
                                <Route path={constant.ROUTE_PROFILE_INFO} component={UserInfo} />
                                <Route path={constant.ROUTE_PROFILE_PASSWORD} component={ChangePassword} />
                            </Switch>
                        </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(Avatar);