import React, { PureComponent } from 'react';

class IconAdd extends PureComponent {
    render() {
        return (
            // <svg 
            //     className='icon-add-license'
            //     width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            //     <path d="M8 1V15" stroke="#5840BB" stroke-miterlimit="10" stroke-linejoin="round"/>
            //     <path d="M1 8H15" stroke="#5840BB" stroke-miterlimit="10" stroke-linejoin="round"/>
            // </svg>
            <svg
                className='icon-add-license'
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8 1v14M1 8h14"
                    stroke="#5840BB"
                    strokeMiterlimit={10}
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default IconAdd;