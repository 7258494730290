import React, { PureComponent } from 'react';
import ExportExcel from '../../utils/Components/ExportExcel';
import Tooltip from '../../utils/Components/Tooltip';
import { common } from '../../utils/Common';
import IconDownload from '../Icons/IconDownload';
import { constant } from '../../services/constant';

const exportedFileCustomerName = 'Danh sach khach hang';
const exportedFileStudentName = 'Danh sach hoc vien';

class Export extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         excelFormatter: this.getExcelFormatter(),
      }
   }

   getExcelFormatter = () => {
      return [
         {
            header: 'Tên',
            name: 'full_name',
         },
         {
            header: 'Email',
            name: 'email',
         },
         {
            header: 'Trạng thái',
            name: 'status',
         },
         {
            header: 'Nghề nghiệp',
            name: 'job_function',
         },
         {
            header: 'Số điện thoại',
            name: 'phone_number',
         },
         {
            header: 'Ngày tạo',
            name: 'joined_at',
            columnRender: this.formatColumnLastLogin
         },
         {
            header: 'Công ty',
            name: 'company',
            columnRender: this.formatColumnLastLogin
         },
         {
            header: 'Sale quản lý',
            name: 'salesclerk_email',
         }
      ];
   }

   formatColumnLastLogin = (value) => {
      if (value) {
         return common.convertToDateTime(value);
      }
      return '';
   }

   formatColumnDateJoined = (value) => {
      if (value) {
         return common.convertToDate(value);
      }
      return '';
   }

   formatColumnCourseList = (courses) => {
      if (courses) {
         return `${courses.map((course, idx) => (`${idx > 0 ? `\n` : ''}${course.display_name}`))}`;
      }
      return '';
   }

   render() {
      return (
         <Tooltip title="Tải xuống danh sách hiện tại">
            <ExportExcel
               className="header-button"
               data={this.props.data}
               fileName={this.props.path === constant.ROUTE_CUSTOMER_LIST ? exportedFileCustomerName : exportedFileStudentName}
               fileFormatter={this.state.excelFormatter}
            >
               <IconDownload />
            </ExportExcel>
         </Tooltip>
      );
   }
}

export default Export;