import React, { PureComponent } from 'react';
import IconSearch from '../Icons/IconSearch';

class SearchBox extends PureComponent {
    state = {
        searchValue: '',
    }
    inputRef = null;
    timerSearch = null;

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                searchValue: this.props.value,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value && this.props.value !== this.state.searchValue) {
            this.setState({
                searchValue: this.props.value,
            });
        }
    }

    search = (e) => {
        clearTimeout(this.timerSearch);
        let value = e.target.value;
        this.setState({
            searchValue: value,
        });
        // this.timerSearch = setTimeout(() => {
        //     this.doSearch(value);
        // }, 500);
    }

    doSearch = (value) => {
        if (this.props.onSearch) {
            this.props.onSearch(`${value}`.trim());
        }
    }

    enterToSearch = (e) => {
        if (e.keyCode === 13) {
            clearTimeout(this.timerSearch);
            this.doSearch(this.state.searchValue);
        }
    }

    selectAllText = (e) => {
        e.target.select();
    }

    focusSearch = () => {
        if (this.inputRef) {
            this.inputRef.focus();
        }
    }

    setInputRef = (r) => {
        this.inputRef = r;
    }

    render() {
        return (
            <div className="search-input-container" onFocus={this.focusSearch} tabIndex="1">
                <IconSearch />
                <input ref={this.setInputRef}
                className="search-input"
                placeholder={this.props.placeholder || ''}
                value={this.state.searchValue}
                onChange={this.search}
                onKeyDown={this.enterToSearch}
                onFocus={this.selectAllText}
                />
            </div>
        );
    }
}

export default SearchBox;