import React, { PureComponent } from 'react';

class IconStudents extends PureComponent {
    render() {
        return (
            <svg
                className="ic-student-list"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16 7l-1 .333v4h1V7zM8.827 10.487a1.98 1.98 0 01-1.654 0L2.667 8.44v3.894C2.667 14.084 5.349 15 8 15c2.65 0 5.333-.916 5.333-2.667V8.44l-4.506 2.048z"
                    fill="#AAA9B7"
                />
                <path
                    d="M15.61 4.393L8.275 1.06a.668.668 0 00-.551 0L.39 4.393a.665.665 0 000 1.213L7.724 8.94a.666.666 0 00.552 0l7.333-3.334a.665.665 0 000-1.213z"
                    fill="#AAA9B7"
                />
            </svg>
        );
    }
}

export default IconStudents;