import React, { Fragment, PureComponent } from 'react'
import { saleServices } from '../../services/saleServices';
// import { instructorService } from '../../services/InstructorService';
import ClonedItem from '../../utils/Components/Common/ClonedItem';
import { toast } from '../../utils/Components/SimpleToast';
import { Toast } from '../../utils/Toast';
import ModalCommon from '../Common/ModalCommon';
import IconClose from '../Icons/IconClose';

export default class LockCustomer extends PureComponent {
    state = {
        openConfirmation: false,
        submitting: false,
    }

    toggleConfirmModal = () => {
        this.setState({
            openConfirmation: !this.state.openConfirmation,
        });
    }

    lockCustomer = async () => {
        await this.setState({ submitting: true });
        if (this.props.selectedData && this.props.selectedData.length !== 0) {
            let email = this.props.selectedData;
            let [success, body] = await saleServices.lockCustomer(email);
            if(success){
                await toast('Đã khoá')
                this.toggleConfirmModal();
                setTimeout(() => {
                this.props.onSuccess();
                }, 100);
            }
        }
        this.setState({ submitting: false });
        
    }

    render() {
        const { children } = this.props;
        return (
            <Fragment>
                {
                React.Children.map(children,
                    child => {
                        return (
                            <ClonedItem
                            child={child}
                            onClick={this.toggleConfirmModal}
                            />
                        )
                    }
                )
                }
                <ModalCommon isOpen={this.state.openConfirmation} toggle={this.toggleConfirmModal}>
                <div className="common-modal-header">
                    <label>Lock</label>
                    <div className="circle-button" onClick={this.toggleConfirmModal}><IconClose /></div>
                </div>
                <div className="common-modal-body">
                    {
                        this.props.selectedData.length === 1 ? (
                            <span>Lock khách hàng “{this.props.selectedData[0]}”.</span>
                        ) : (
                            <span>Những khách hàng được chọn sẽ bị lock.</span>
                            )
                    }
                    <div className="modal-actions">
                        <button className="btn-cancel" onClick={this.toggleConfirmModal}>Đóng</button>
                        <button className="btn-active bg-orange" onClick={this.lockCustomer} disabled={this.state.submitting}>Lock</button>
                    </div>
                </div>
                </ModalCommon>
            </Fragment>
        );
    }
}
