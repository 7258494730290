import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Slide } from 'react-toastify';

const containerId = 'cme-toast-id';

const Success = (msg) => {
    toast(
        <div className="toast-content">
            <i className="ic-toast-success icon-size-16"></i>
            <label className="toast-content-msg">{msg}</label>
        </div>
        , {
            position: toast.POSITION.TOP_CENTER,
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            containerId: containerId,
            draggable: false,
            className: 'cme-toast toast-success',
        }
    );
};

const Info = (msg) => {
    toast(
        <div className="toast-content">
            <i className="ic-toast-success icon-size-16"></i>
            <label className="toast-content-msg">{msg}</label>
        </div>
        , {
            position: toast.POSITION.TOP_CENTER,
            type: toast.TYPE.SUCCESS,
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            containerId: containerId,
            draggable: false,
            className: 'cme-toast toast-success',
        }
    );
};

const Fail = (msg) => {
    toast(
        <div className="toast-content">
            <i className="ic-toast-error icon-size-16"></i>
            <label className="toast-content-msg">{msg}</label>
        </div>
        , {
            position: toast.POSITION.TOP_CENTER,
            type: toast.TYPE.ERROR,
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            containerId: containerId,
            draggable: false,
            className: 'cme-toast toast-error',
        }
    );
};

const Warning = (msg) => {
    toast(
        <div className="toast-content">
            <i className="ic-toast-warning icon-size-16"></i>
            <label className="toast-content-msg">{msg}</label>
        </div>
        , {
            position: toast.POSITION.TOP_CENTER,
            type: toast.TYPE.WARNING,
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            containerId: containerId,
            draggable: false,
            className: 'cme-toast toast-warning',
        }
    );
}

const dismiss = (toastId) => {
    toast.dismiss(toastId || null);
}


const CloseButton = ({ closeToast }) => {
    return (
        <button className="btn-close-toast" onClick={closeToast}><i className="ic-close-toast icon-size-16" /></button>
    )
};

const renderContainer = () => {
    return (
        <ToastContainer
            containerId={containerId}
            className="cme-toast-container"
            closeButton={<CloseButton />}
            transition={Slide}
            enableMultiContainer
        />
    )
}

export const Toast = {
    renderContainer,
    Success,
    Info,
    Fail,
    Warning,
    dismiss,
};