import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import DatePicker from '../../utils/Components/DatePicker';
import IconFilter from '../Icons/IconFilter';
import _ from 'lodash';
import IconCalendar from '../Icons/IconCalendar';
import IconFilterActive from '../Icons/IconFilterActive';

class FilterCourses extends PureComponent {
    state = {
        openFilter: false,
        start: '',
        end: '',
    }

    addBackDrop = (e) => {
        if (this.state.openFilter && !ReactDOM.findDOMNode(this).contains(e.target)) {
            this.closeFilter();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.addBackDrop);
    }

    componentDidMount() {
        window.addEventListener('click', this.addBackDrop);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.filter, this.props.filter)) {
            this.setState({
                start: this.props.filter ? this.props.filter.start || '' : '',
                end: this.props.filter ? this.props.filter.end || '' : '',
            });
        }
    }


    openFilter = () => {
        this.setState({
            openFilter: true,
        });
    }

    closeFilter = () => {
        this.setState({
            openFilter: false,
            start: this.props.filter ? this.props.filter.start || '' : '',
            end: this.props.filter ? this.props.filter.end || '' : '',
        });
    }

    setStartDate = (value) => {
        this.setState({ start: value });
    }
    setEndDate = (value) => {
        this.setState({ end: value });
    }

    clearFilter = async () => {
        let filterObj = {
            start: '',
            end: '',
        };
        this.props.onFilter(filterObj);
        this.closeFilter();
    }

    filter = () => {
        let filterObj = {
            start: this.state.start,
            end: this.state.end,
        };
        this.props.onFilter(filterObj);
        this.setState({
            openFilter: false,
        });
    }

    render() {
        return (
            <div className={`filter-dropdown ${this.state.openFilter ? 'show' : ''}`}>
                <div className="filter-dropdown-toggler" onClick={this.openFilter}>
                <div className={`filter-courses ${(this.state.start || this.state.end) ? 'active' : ''}`}>
                    {
                        (this.state.start || this.state.end) ?
                            <IconFilterActive />
                            :
                            <IconFilter />
                    }
                </div>
                </div>
                <div className="filter-dropdown-menu disable-select">
                <label>Thời gian học</label>
                <DatePicker
                    name="start"
                    value={this.state.start}
                    placeholder="Từ ngày"
                    suffixIcon={<IconCalendar />}
                    onChange={this.setStartDate}
                />
                <DatePicker
                    name="end"
                    value={this.state.end}
                    placeholder="Đến ngày"
                    suffixIcon={<IconCalendar />}
                    onChange={this.setEndDate}
                />
                <div className="filter-actions">
                    <span className="color-warning" onClick={this.clearFilter}>Bỏ lọc</span>
                    <span className="color-active" onClick={this.filter}>Xong</span>
                </div>
                </div>
            </div>
        );
    }
}

export default FilterCourses;