import React, { Fragment } from 'react';
import { saleServices } from '../../services/saleServices';
import { Row, Col } from 'reactstrap';
import IconClose from '../Icons/IconClose';
import IconDown from '../Icons/IconDown';
import {withRouter} from 'react-router-dom'
import Select, { components } from 'react-select';
import { toast } from '../../utils/Components/SimpleToast';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
        <IconDown/>
        </components.DropdownIndicator>
    );
};
class CreateSale extends React.Component{
    constructor(props){
        super();
        this.state = {
            email: '',
            fullName: '',
            region: '',
            role:'',
            emailError: false,
            fullNameError: false,
            data: '',
            roleName: '',
            isSubmitting: false,
            roleDescriptionError: false,
        };
    };

    componentDidMount(){
        this.getSaleRole()
    }

    getSaleRole = async () => {
        let [success, body] = await saleServices.getSaleRole();
        if(success){
            let data = body.data;
            this.setState({data: data})
        }
    }
    
    renderRoleSelect = () => {
        let data = this.state.data;
        let userRole = data.user_role;
        if(userRole !== 'SALE_AREA'){
            let roles = '' ;
            if(userRole === "SALE_DIRECTOR"){
                roles = data.roles.filter((role, idx) => role.name !== userRole);
            } else if(userRole === "SALE_MANAGER") {
                roles = data.roles.filter((role, idx) => role.name === "SALE_AREA");
            } if (userRole === null) {
                roles = data.roles;
            }
            return(
                <Row className="input-row license-dropdown ">
                    <Col md={3}>
                        <div className="title-input">Role Sale<span className="ic-start">*</span></div>
                    </Col>
                    <Col md={9}>
                    <Select
                            onChange={this.chooseSaleRole}
                            options={roles && roles.map((role, idx)=>{
                                if (role.name === 'SALE_AREA') {
                                    role.description = 'Sale Member';
                                }
                                return(
                                    {value: role.name, label: role.description}
                                )
                            })}
                            components={{DropdownIndicator}}
                            className='elite-select'
                            classNamePrefix='elite-sale'
                        />
                    {this.state.roleDescriptionError && <div className="message-error">{this.state.roleDescriptionError}</div>}
                    </Col>
                </Row>
            )
        }
    }

    handleInputChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
        this.validateInput(e.target.id, e.target.value);
    }

    validateInput = (field, value) => {
        if(field === void 0 && value === void 0){
            let allowSubmit = false;
            let emailError = this.validateInput('email', this.state.email)
            let fullNameError = this.validateInput('fullName', this.state.fullName);
            let roleDescriptionError = this.validateInput('select', this.state.roleName);
            if(emailError && fullNameError && roleDescriptionError){
                allowSubmit = true;
            }
            return allowSubmit;
        }
        if(field === 'email'){
            if(value === ''){
                this.setState({emailError: "Vui lòng nhập email"});
                return false;
            } else if ((/\S+@\S+\.\S+/.test(value)) === false) {
                this.setState({
                    emailError: 'Email không hợp lệ',
                });
                return false;
            }else {
                this.setState({emailError: ""})
            }
        } else if(field ==='fullName'){
            if(value === ''){
                this.setState({fullNameError: "Vui lòng nhập tên"});
                return false;
            } else {
                this.setState({fullNameError: ""});
            }
        } else if(field ==='select'){
            if(value === ''){
                this.setState({roleDescriptionError: "Vui lòng chọn"});
                return false;
            } else {
                this.setState({roleDescriptionError: ""});
            }
        }
        return true;
    }

    chooseSaleRole = (e) => {
        this.setState({
            roleName: e.value,
        },()=>{
            this.validateInput('select', e.name)
        });

    };

    handleSubmit = async (e) => {
        e.preventDefault();
        if(this.validateInput()){
            await this.setState({isSubmitting: true})
            let data = new FormData();
            data.append('email', this.state.email);
            data.append('full_name', this.state.fullName);
            data.append('region_uid', '' );
            data.append('role_name', this.state.roleName);
            let [success, body] = await saleServices.createSale(data);
            if(success){
                await toast('Đã tạo thành công');
                this.toggle();
                setTimeout(() => {
                    if(this.props.history.location.pathname === '/sales'){
                        this.props.reloadData();
                    } else {
                        this.props.history.push('/sales')
                    }
                }, 100);
                this.props.history.push('/sales');
            }
        }
        this.setState({
            isSubmitting: false,

        })
    };

    toggle = () => {
        if(this.props.handleToggle){
            this.props.handleToggle();
        }
    }

    render(){
        return(
            <div className="create-sale">
                {this.state.data  &&
                <Fragment>
                    <div className="common-modal-header">
                        <label>Thêm nhân viên</label>
                        <div className="circle-button" onClick={this.toggle}><IconClose/></div>
                    </div>
                    <div className="common-modal-body">
                    <Row className="input-row">
                        <Col md={3}>
                            <div className="title-input">Email<span className="ic-start">*</span></div>
                        </Col>
                        <Col md={9}>
                            <input
                                className="field-input"
                                placeholder='Email'
                                id="email"
                                value={this.state.value}
                                onChange={this.handleInputChange}
                            />
                            {this.state.emailError && <div className="message-error">{this.state.emailError}</div>}
                        </Col>
                    </Row>
                    <Row className="input-row">
                        <Col md={3}>
                            <div className="title-input">Tên<span className="ic-start">*</span></div>
                        </Col>
                        <Col md={9}>
                            <input
                                className="field-input"
                                placeholder='Tên'
                                id="fullName"
                                value={this.state.fullName}
                                onChange={this.handleInputChange}
                            />
                            {this.state.fullNameError && <div className="message-error">{this.state.fullNameError}</div>}
                        </Col>
                    </Row>
                    {/* <Row className="input-row">
                        <Col md={3}>
                            <div className="title-input">Region</div>
                        </Col>
                        <Col md={9}>
                            <input
                                className="field-input"
                                placeholder='Region'
                                id="region"
                                value={this.state.region}
                                onChange={this.handleInputChange}
                            />
                        </Col>
                    </Row> */}
                    {this.renderRoleSelect()}
                    <div className="modal-actions">
                        <button className="btn-cancel" onClick={this.toggle}>Đóng</button>
                        <button className="btn-active bg-green" onClick={this.handleSubmit} disabled={this.state.isSubmitting}>Thêm</button>
                    </div>
                </div>
                </Fragment>
                }
            </div>
        )
    }
}
export default withRouter(CreateSale);