import React, { Fragment, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import withInstructor from '../../HOC/InstructorHOC';
import IconToggleMenu from '../Icons/IconToggleMenu';

class LeftMenu extends PureComponent {
    controlRef = null;

    addBackDrop = (e) => {
        if (this.props.showMenu && !ReactDOM.findDOMNode(this.controlRef).contains(e.target)) {
            this.props.toggleMenu();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.addBackDrop);
    }

    componentDidMount() {
        window.addEventListener('click', this.addBackDrop);
    }

    setControlRef = (r) => {
        this.controlRef = r;
    }

    render() {
        return (
            <Fragment>
                <div className="header-button" onClick={this.props.toggleMenu} ref={this.setControlRef}>
                <IconToggleMenu />
                </div>
            </Fragment>
        );
    }
}

export default withInstructor(LeftMenu);