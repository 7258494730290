import React, { Fragment, PureComponent } from 'react';
import { common } from '../../utils/Common';
import _cloneDeep from 'lodash/cloneDeep';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import { saleServices } from '../../services/saleServices';
import SuperTable from '../../utils/Components/SuperTable/SuperTable';
import PaginationModel from '../../utils/Components/SuperTable/Models/PaginationModel';
import ActionGroup from '../Sale/ActionGroup';
import { Tooltip } from 'reactstrap';
import SearchBox from '../Common/SearchBox';
import LeftMenu from '../Common/LeftMenu';
import IconAdd from '../Icons/IconAdd';
import IconNotFound from '../Icons/IconNotFound';
import IconStudent from '../Icons/IconStudent';
import { Link } from 'react-router-dom';
import { serviceService } from '../../services/serviceServices';
import IconLicense from '../Icons/IconLicense';
import { userServices } from '../../services/userServices';
import { constant } from '../../services/constant';
import { ReactComponent as IcLoading } from '../../assets/icons/loading.svg';
import withUserType from '../../HOC/UserTypeHOC';


class Licenses extends PureComponent {

    state = {
        data: [],
        columns: [],
        selectedData: [],
        search: '',
        pagination: new PaginationModel(),
        isOpen: false,
    }

    _isMounted = false;

    componentWillMount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.prepareColumn();
        this.loadData();
    }

    handlePaginate = (newPagination) => {
        if (newPagination instanceof PaginationModel) {
            let pagination = _cloneDeep(newPagination);
            this.setState({
                pagination: pagination,
            });
            this.loadData(pagination);
        }
    }

    numberFormatter = (cell, row, rowIndex) => {
        let pageNo = this.state.pagination.pageNo;
        let index = rowIndex;
        if (pageNo !== 1) {
            index = (rowIndex + 1) + constant.DEFAULT_PAGE_SIZE * (pageNo - 1);
        } else {
            index = index + 1;
        }
        return index;
    }

    createdAtFormatter = (cel, row, rowIndex) => {
        return common.convertToDateTimeForSale(row.created_at);
    }

    dueDateFormatter = (cel, row, rowIndex) => {
        return common.convertDayMonthYear(row.due_date)
    }

    statusFormatter = (cell, row, rowIndex) => {
        if (row.license.status === 'USED') {
            return <div className="red-status">Used</div>;
        }
        else if (row.license.status === 'AVAILABLE') {
            return <div className="green-status">Available</div>;
        }
        return row.license.status;
    }

    prepareColumn = () => {
        this.setState({
            columns: [{
                header: 'STT',
                cell: '',
                formatter: this.numberFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Name',
                cell: 'license_name',
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Trạng thái',
                cell: 'license.status',
                formatter: this.statusFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Ngày tạo',
                cell: 'license.created_at',
                formatter: this.createdAtFormatter,
                headerClassName: 'content-no-wrap',
                className: 'content-min-width',
            }, {
                header: 'Ngày hết hạn',
                cell: 'license.due_date',
                formatter: this.dueDateFormatter,
                headerClassName: 'content-no-wrap',
                className: 'content-min-width',
            }]
        });
    }

    loadData = async (pagination, searchValue) => {
        if (!this._isMounted) {
            return;
        }
        let search = this.state.search;
        if (searchValue !== void 0 && searchValue !== null) {
            search = searchValue;
        }
        let newPagination = _cloneDeep(this.state.pagination);
        if (pagination) {
            newPagination = pagination;
        }
        await this.setState({ isLoading: true });
        let body = {
            "page": newPagination.pageNo,
            "page_size":  newPagination.pageSize,
            "search": searchValue,
            "author_email": this.props.email
        }
        const [isSuccess, response] = await serviceService.getListServiceOfUser(body);
        if (this._isMounted) {
            let data = [];
            if (isSuccess) {
                if (response && response.data) {
                    data = response.data.content || [];
                    newPagination = new PaginationModel(response.data.pageSize, response.data.currentPage, response.data.totalRows);
                }
            }
            if (!_isEqual(data, this.state.data)) {
                await setTimeout(() => {
                    this.setState({
                    data: data,
                    pagination: newPagination,
                    search: searchValue || '',
                    isLoading: false,
                    });
                }, 300);
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        }
    }


    render() {
        return (
            <Fragment>
                {
                    this.state.isLoading &&
                    <div className="content-loading">
                        <IcLoading />
                    </div>
                }
                <div className="instructor-header">
                    <div className="left-group">
                        <LeftMenu />
                        <span className="page-name disable-select">Danh sách Licenses</span>
                    </div>
                    <div className="center-container">
                        <SearchBox
                            value={this.state.search}
                            placeholder="Tên licenses"
                            onSearch={this.handleSearch}
                        />
                    </div>
                </div>
                <div className="instructor-body">
                    <div className="instructor-wrap">
                        {
                            this.state.data.length !== 0 &&
                            <SuperTable
                                rowKey="uid"
                                unit="Danh sách Licenses"
                                data={this.state.data}
                                columns={this.state.columns}
                                pagination={this.state.pagination}
                                onPaginate={this.handlePaginate}
                                rowsActive={this.state.selectedData}
                            />
                        }
                        {
                            !this.state.isLoading &&
                            this.state.data.length === 0 &&
                            <div className="container-empty-student">
                                {
                                this.state.search.trim() !== '' ? (
                                    <Fragment>
                                        <IconNotFound />
                                        <div className="label-emtpy-student">
                                            {`Không tìm thấy kết quả nào \nvới từ khoá “${this.state.search}”`}
                                        </div>
                                        <div className="remove-filter" onClick={this.removeFilter}>Xoá bộ lọc</div>
                                    </Fragment>
                                ) : (
                                        <Fragment>
                                            {/* <IconStudent /> */}
                                            <i className="icon-size-48 ic-license" />
                                            <div className="label-emtpy-student">Chưa có Licenses nào</div>
                                        </Fragment>
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withUserType(Licenses);