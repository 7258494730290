import React, { Fragment, PureComponent } from 'react'
// import { instructorService } from '../../services/InstructorService';
import ClonedItem from '../../utils/Components/Common/ClonedItem';
import { toast } from '../../utils/Components/SimpleToast';
import ModalCommon from '../Common/ModalCommon';
import IconClose from '../Icons/IconClose';

export default class UnenrollBtn extends PureComponent {
    state = {
        openConfirmation: false,
        submitting: false,
    }

    toggleConfirmModal = () => {
        this.setState({
            openConfirmation: !this.state.openConfirmation,
        });
    }

    unenrollSelected = async () => {
        await this.setState({ submitting: true });
        if (this.props.selectedData && this.props.selectedData.length !== 0) {
            let body = {
                courseId: this.props.courseId,
                action: 'unenroll',
                identifiers: this.props.selectedData,
                reason: 'No reason',
                role: 'Học viên',
            };
            // const [success, message, response] = await instructorService.enrollStudents(body);
            // console.log(response);
            // if (success) {
            //     if (response.data && response.data.results) {
            //     if (response.data.results.length === 1) {
            //         await toast(`Đã huỷ đăng ký học viên “${response.data.results[0].identifier}” trong khóa học này`);
            //     } else {
            //         await toast(`Đã huỷ đăng ký ${response.data.results.length} học viên trong khóa học này`);
            //     }
            //     }
            //     this.toggleConfirmModal();
            //     setTimeout(() => {
            //     this.props.onSuccess();
            //     }, 100);
            // }
        }
        this.setState({ submitting: false });
    }

    render() {
        const { children } = this.props;
        return (
            <Fragment>
                {
                React.Children.map(children,
                    child => {
                        return (
                            <ClonedItem
                            child={child}
                            onClick={this.toggleConfirmModal}
                            />
                        )
                    }
                )
                }
                <ModalCommon isOpen={this.state.openConfirmation} toggle={this.toggleConfirmModal}>
                <div className="common-modal-header">
                    <label>Hủy ghi danh</label>
                    <div className="circle-button"><IconClose /></div>
                </div>
                <div className="common-modal-body">
                    {
                        this.props.selectedData.length === 1 ? (
                            <span>Hủy ghi danh học viên “{this.props.selectedData[0]}” trong khóa học này của bạn.</span>
                        ) : (
                            <span>Những học viên được chọn sẽ bị hủy ghi danh trong khóa học này của bạn.</span>
                            )
                    }
                    <div className="modal-actions">
                        <button className="btn-cancel" onClick={this.toggleConfirmModal}>Đóng</button>
                        <button className="btn-active bg-orange" onClick={this.unenrollSelected} disabled={this.state.submitting}>Huỷ ghi danh</button>
                    </div>
                </div>
                </ModalCommon>
            </Fragment>
        );
    }
}
