import React, { PureComponent } from 'react';

class ClonedItem extends PureComponent {

    doClick = async (...args) => {
        if (this.props.child && this.props.child.props && this.props.child.props.onClick) {
            await this.props.child.props.onClick(...args);
        }
        if (this.props.onClick) {
            this.props.onClick(...args);
        }
    }
    doMouseOver = async (...args) => {
        if (this.props.child && this.props.child.props && this.props.child.props.onMouseOver) {
            await this.props.child.props.onMouseOver(...args);
        }
        if (this.props.onMouseOver) {
            this.props.onMouseOver(...args);
        }
    }

    render() {
        const { innerRef, child, ...props } = this.props;
        return React.cloneElement(child, {
            ...props,
            ref: innerRef || null,
            onClick: this.doClick,
            onMouseOver: this.doMouseOver,
        });
    }
}

export default React.forwardRef((props, ref) => <ClonedItem
    innerRef={ref} {...props}
/>);