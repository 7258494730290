import React, { PureComponent } from 'react';

class IconDownload extends PureComponent {
   render() {
      return (
         <svg
            className="icon-download"
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M8 2.38419e-07V11.25"
               stroke="white"
               strokeWidth="1.5"
               strokeMiterlimit={10}
               strokeLinejoin="round"
            />
            <path
               d="M13 6.25L8 11.25L3 6.25"
               stroke="white"
               strokeWidth="1.5"
               strokeMiterlimit={10}
               strokeLinecap="square"
               strokeLinejoin="round"
            />
            <path
               d="M15 15.25H0.999939"
               stroke="white"
               strokeWidth="1.5"
               strokeMiterlimit={10}
               strokeLinejoin="round"
            />
         </svg>
      );
   }
}

export default IconDownload;