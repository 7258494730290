import React, { PureComponent } from 'react';
import { userServices } from '../../services/userServices';
import { Toast } from '../../utils/Toast';


class ChangePassword extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            currentPasswordError: '',
            newPasswordError: '',
            repeatNewPasswordError: '',
            showPass: false,
            showPass2: false,
            showPass3: false,
            isSubmitting: false,
        }
    }

    handleShowHidePass = () => {
        this.setState({
            showPass: !this.state.showPass,
        })
    }

    handleShowHidePass2 = () => {
        this.setState({
            showPass2: !this.state.showPass2,
        })
    }

    handleShowHidePass3 = () => {
        this.setState({
            showPass3: !this.state.showPass3,
        })
    }

    validateInput = (field, value) => {
        if (field === void 0 && value === void 0) {
            let allowSubmit = false;
            let currentPasswordError = this.validateInput('currentPassword', this.state.currentPassword);
            let newPasswordError = this.validateInput('newPassword', this.state.newPassword);
            let repeatNewPasswordError = this.validateInput('repeatNewPassword', this.state.repeatNewPassword);

            if (currentPasswordError && newPasswordError && repeatNewPasswordError) {
                allowSubmit = true;
            }
            return allowSubmit;
        }
        if (field === 'currentPassword') {
            if (value === '') {
                this.setState({currentPasswordError: "Vui lòng nhập mật khẩu cũ"});
                return false;
            } else {
                this.setState({currentPasswordError: ""})
            }
        } else if (field === 'newPassword') {
            if (value === '') {
                this.setState({newPasswordError: "Vui lòng nhập mật khẩu mới"});
                return false;
            } else if (this.state.repeatNewPassword !== '' && value !== this.state.repeatNewPassword) {
                this.setState({newPasswordError: "Mật khẩu không khớp"});
                return false;
            }else {
                this.setState({
                    newPasswordError: "",
                    repeatNewPasswordError: "",
                });
            }
        } else if (field === 'repeatNewPassword') {
            if (value === '') {
                this.setState({repeatNewPasswordError: "Vui lòng nhập lại mật khẩu mới"});
                return false;
            } else if (value !== this.state.newPassword) {
                this.setState({repeatNewPasswordError: "Mật khẩu không khớp"});
                return false;
            } else {
                this.setState({
                    repeatNewPasswordError: "",
                    newPasswordError: "",
                });
            }
        }
        return true;
    }


    handleChangeValue = (e) => {
        console.log(e.target.value)
        console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value,
        })
        this.validateInput(e.target.name, e.target.value)
    }

    handleChangePassword = async (e) => {
        e.preventDefault();
        if (this.validateInput()) {
            await this.setState({isSubmitting: true})
            let body = {
                "current_password": this.state.currentPassword,
                "new_password": this.state.newPassword,
            }
            let [success, response] = await userServices.changePassword(body);
            if (success === true) {
                Toast.Info('Thay đổi mật khẩu thành công')
                this.setState({
                    currentPassword: '',
                    newPassword: '',
                    repeatNewPassword: '',
                })
            }
        }
        this.setState({isSubmitting: false});
    }

    render() {
        return (
            <div className='password-tab login-container'>
                <form onSubmit={this.handleChangePassword} className='login-content'>
                    <div className='title-input'>Mật khẩu hiện tại<span className='ic-start'>*</span></div>
                    <div className='pass-input'>
                        <input
                            className='field-input'
                            placeholder='Nhập mật khẩu cũ'
                            name='currentPassword'
                            type={this.state.showPass ? "text" : "password"}
                            value={this.state.currentPassword}
                            onChange={this.handleChangeValue}
                        />
                        <i
                            className={`ic-16 ${this.state.showPass ? 'ic-hide-pass' : 'ic-show-pass'}`}
                            onClick={this.handleShowHidePass}
                        />
                        {this.state.currentPasswordError && <div className="message-error">{this.state.currentPasswordError}</div>}
                    </div>
                    <div className='title-input'>Mật khẩu mới<span className='ic-start'>*</span></div>
                    <div className='pass-input'>
                        <input
                            className='field-input'
                            placeholder='Nhập mật khẩu mới'
                            name='newPassword'
                            type={this.state.showPass2 ? "text" : "password"}
                            value={this.state.newPassword}
                            onChange={this.handleChangeValue}
                        />
                        <i
                            className={`ic-16 ${this.state.showPass2 ? 'ic-hide-pass' : 'ic-show-pass'}`}
                            onClick={this.handleShowHidePass2}
                        />
                        {this.state.newPasswordError && <div className="message-error">{this.state.newPasswordError}</div>}
                    </div>
                    <div className='title-input'>Nhập lại mật khẩu mới<span className='ic-start'>*</span></div>
                    <div className='pass-input'>
                        <input
                            className='field-input'
                            placeholder='Nhập lại mật khẩu mới'
                            name='repeatNewPassword'
                            type={this.state.showPass3 ? "text" : "password"}
                            value={this.state.repeatNewPassword}
                            onChange={this.handleChangeValue}
                        />
                        <i
                            className={`ic-16 ${this.state.showPass3 ? 'ic-hide-pass' : 'ic-show-pass'}`}
                            onClick={this.handleShowHidePass3}
                        />
                        {this.state.repeatNewPasswordError && <div className="message-error">{this.state.repeatNewPasswordError}</div>}
                    </div>
                    <button className='login-button' disabled={this.state.isSubmitting}>Đổi mật khẩu</button>
                </form>
            </div>
        )
    }
}

export default ChangePassword;