import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { constant } from '../../services/constant';
import { userServices } from '../../services/userServices';
import IconClose from '../Icons/IconClose';
import IconLicense from '../Icons/IconLicense';
import { Toast } from '../../utils/Toast';
import IconSaleList from '../Icons/IconSaleList';
import IconUserList from '../Icons/IconUserList';
import IconAvatar from '../Icons/IconAvatar';
import IconStudents from '../Icons/IconStudents';
import { common } from '../../utils/Common';
import withUserType from '../../HOC/UserTypeHOC';

const menuSales = 'sales';
const menuCustomers = 'customers';
const menuLicenses = 'licenses';
const menuStudents = 'students';

class LeftMenuContainer extends PureComponent {
    state = {
        activeMenu: null,
        menuList: [],
        courseName: '',
        org: '',  // waiting..
        number: '',  // waiting..

        prevPage: '',
        userInfo: '',
    }

    componentDidMount() {
        this.getUserProfile();
        const prevPage = localStorage[constant.prevPageParam] || sessionStorage[constant.prevPageParam];
        if (prevPage) {
            localStorage.removeItem(constant.prevPageParam);
            sessionStorage[constant.prevPageParam] = prevPage;
            this.setState({
                prevPage: prevPage,
            });
        }
    };

    getUserProfile = async () => {
        let [success, body] = await userServices.getUserProfile();
        if (success === true) {
            this.setState({
                userInfo: body.data,
            }, ()=>{
                this.getMenuList();
            })
        }
    };

    getMenuList = () => {
        let routePath = this.props.location.pathname;
        let activeMenu = null;
        if (routePath === '/sales') {
            activeMenu = menuSales;
        } else if (routePath === '/customers') {
            activeMenu = menuCustomers;
        } else if (routePath === '/licenses') {
            activeMenu = menuLicenses;
        } else if (routePath === '/students') {
            activeMenu = menuStudents;
        }

        let menuList = [];
        if(this.state.userInfo && this.state.userInfo.role_name === "SALE_AREA"){
            menuList = [
                {
                    name: menuCustomers,
                    label: 'Danh sách khách hàng',
                    icon: IconUserList,
                    link: constant.ROUTE_CUSTOMER_LIST,
                }, {
                    name: menuStudents,
                    label: 'Danh sách học viên',
                    icon: IconUserList,
                    link: constant.ROUTE_STUDENTS,
                }, {
                    name: menuLicenses,
                    label: 'Danh sách Licenses',
                    icon: IconLicense,
                    link: constant.ROUTE_LICENSES,
                },
            ];
        } else if (this.props.userType === 'REPORTER'){
            menuList = [
                {
                    name: menuCustomers,
                    label: 'Danh sách khách hàng',
                    icon: IconUserList,
                    link: constant.ROUTE_CUSTOMER_LIST,
                }, {
                    name: menuStudents,
                    label: 'Danh sách học viên',
                    icon: IconStudents,
                    link: constant.ROUTE_STUDENTS,
                }, {
                    name: menuSales,
                    label: 'Danh sách nhân viên',
                    icon: IconSaleList,
                    link: constant.ROUTE_SALES,
                }
            ];
        } else {
            menuList = [
                {
                    name: menuCustomers,
                    label: 'Danh sách khách hàng',
                    icon: IconUserList,
                    link: constant.ROUTE_CUSTOMER_LIST,
                }, {
                    name: menuStudents,
                    label: 'Danh sách học viên',
                    icon: IconStudents,
                    link: constant.ROUTE_STUDENTS,
                }, {
                    name: menuSales,
                    label: 'Danh sách nhân viên',
                    icon: IconSaleList,
                    link: constant.ROUTE_SALES,
                }, {
                    name: menuLicenses,
                    label: 'Danh sách Licenses',
                    icon: IconLicense,
                    link: constant.ROUTE_LICENSES,
                },
            ];
        }

        this.setState({
            menuList: menuList,
            activeMenu: activeMenu,
        });
    };

    preventClose = (e) => {
        e.stopPropagation();
    };

    handleLogout = async () => {
        let [success, body] = await userServices.logout();
        if (success === true) {
            // clear token & redirect to login
            localStorage.clear();
            this.props.history.push(constant.ROUTE_LOGIN);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location !== this.props.location) {
            this.getMenuList();
        }
    };

    render() {
        return (
            <div className={`left-menu-container ${this.props.showMenu ? 'show' : ''}`} onClick={this.preventClose}>
                <div className="leftmenu-header">
                <div className="header-button" onClick={this.props.toggleMenu}><IconClose /></div>
                </div>
                <div className="leftmenu-content">
                    <div className="menu-list">
                        {
                            this.state.menuList.map((item) => {
                                const Icon = item.icon;
                                return (
                                <Link key={item.name}
                                    className={`menu-item ${this.state.activeMenu === item.name ? 'active' : ''}`}
                                    to={item.link}
                                    onClick={this.props.toggleMenu}
                                >
                                    <Icon /><span>{item.label}</span>
                                </Link>
                                )
                            })
                        }
                    </div>
                    {
                        this.state.userInfo &&
                        <div className="info-user">
                            {
                                    this.state.userInfo.avatar_url ?
                                    <img
                                        src={this.state.userInfo.avatar_url}
                                        alt=''
                                        className='avatar-sidebar'
                                    />
                                    :
                                    <div className='avatar-sidebar'>
                                        <IconAvatar/>
                                    </div>
                                }
                            <div className='name'>{this.state.userInfo.full_name}</div>
                            <div className='email'>{this.state.userInfo.email}</div>
                            <div className="separate-line" />
                            <Link
                                className='acount-link'
                                to={constant.ROUTE_PROFILE}
                                onClick={this.props.toggleMenu}
                            >
                                <span>Tài khoản</span>
                            </Link>
                            <div className='log-out' onClick={this.handleLogout}>Đăng xuất</div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(withUserType(LeftMenuContainer));