import { Toast } from '../utils/Toast';
import { API_CONST } from "../Api";

const createSale = async (data) => {
    let response;
    let token = localStorage.getItem('token')
    let options = {
        method: "POST",
        body: data,
        headers: {
            "Authorization": "Bearer " + token,
        }
    }
    let url = API_CONST.CREATE_SALE;
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    }
    catch (error) {
        return [false, null]
    }

};

const getListSales = async (data) => {
    let response;
    let url = API_CONST.GET_LIST_USER;
    let token = localStorage.getItem('token');
    let options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [ body.errorCode === 0, body ];
    } catch (error) {
        return [false, null];
    }
};

const getListCustomers = async (data) => {
    let response;
    let url = API_CONST.GET_LIST_CUSTOMER;
    let token = localStorage.getItem('token');
    let options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    } catch (error) {
        return [false, null];
    }
};

const getSaleRole = async () => {
    let response;
    let url = API_CONST.GET_SALE_ROLE;
    let token = localStorage.getItem('token');
    let options = {
        method: 'GET',
        headers: {
            "Authorization": "Bearer " + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [ body.errorCode === 0, body ];
    } catch (error) {
        return [false, null];
    }
};

const getUserInfoByEmail = async (email) => {
    let response;
    let token = localStorage.getItem('token');
    let url = API_CONST.GET_USER_INFO_BY_EMAIL(email);
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    } catch (error) {
        return [false, null];
    }

};

const lockCustomer = async (email) => {
    let response;
    let url = API_CONST.LOCK_CUSTOMER(email);
    let token = localStorage.getItem('token');
    let options = {
        method: 'PUT',
        headers: {
            "Authorization": "Bearer " + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [ body.errorCode === 0, body ];
    } catch (error) {
        return [false, null];
    }
};

const unLockCustomer = async (email) => {
    let response;
    let url = API_CONST.UN_LOCK_CUSTOMER(email);
    let token = localStorage.getItem('token');
    let options = {
        method: 'PUT',
        headers: {
            "Authorization": "Bearer " + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [ body.errorCode === 0, body ];
    } catch (error) {
        return [false, null];
    }
};

const transferCustomer = async (email, body) => {
    let response;
    let url = API_CONST.TRANSFER_CUSTOMER(email);
    let token = localStorage.getItem('token');
    let options = {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    } catch (error) {
        return [false, null];
    }
};

const updateInfoUser = async (email, data) => {
    let response;
    let url = API_CONST.UPDATE_USER(email);
    let token = localStorage.getItem('token');
    let options = {
        method: 'PUT',
        body: data,
        headers: {
            'Authorization': 'Bearer ' + token,
            // 'Content-Type': 'application/json',
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode ===0, body];
    } catch (error) {
        return [false, null];
    }
};

const uploadContractFile = async (data) => {
    let response;
    let token = localStorage.getItem('token');
    let options = {
        method: 'POST',
        body: data,
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    }
    let url = API_CONST.UPLOAD_CONTRACT_FILE;
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    } catch (error) {
        return [false, null];
    }
};

const getContractFiles = async (data) => {
    let response;
    let token = localStorage.getItem('token');
    let options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
    };
    let url = API_CONST.GET_CONTRACT_FILES;
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    } catch (error) {
        return [false, null];
    }
};

const deleteContractFile = async (uid) => {
    let response;
    let token = localStorage.getItem('token');
    let url = API_CONST.DELETE_CONTRACT_FILE(uid);
    let options = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
        },
    };
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body]
    } catch (error) {
        return [false, null];
    }
};

const getStudentsList = async (data) => {
    let response;
    let url = API_CONST.GET_STUDENTS_LIST;
    let token = localStorage.getItem('token');
    let options = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
        }
    }
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    } catch (error) {
        return [false, null];
    }
};

const syncData = async () => {
    let response;
    let token = localStorage.getItem('token');
    let options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    };
    let url = API_CONST.SYNC_DATA;
    try {
        response = await fetch(url, options);
        let body = await response.json();
        if (body && body.errorCode !== 0) {
            Toast.Fail(body.message);
        }
        return [body.errorCode === 0, body];
    } catch (error) {
        return [false, null];
    }
};

export const saleServices = {
    createSale,
    getListSales,
    getSaleRole,
    getUserInfoByEmail,
    lockCustomer,
    unLockCustomer,
    transferCustomer,
    updateInfoUser,
    uploadContractFile,
    getContractFiles,
    deleteContractFile,
    getStudentsList,
    getListCustomers,
    syncData,
}