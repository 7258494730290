const Licenses = [
   {
      value: {
         'license_type': 'TRIAL',
         'service_code': '',
         'term': '',
      },
      lable: 'Dùng thử 14 ngày'
   },
   // Main-Live
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Main_Service',
         'term': 'HALF_A_YEAR',
      },
      lable: 'EliteLearning Pro・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Main_Service',
         'term': 'YEARLY',
      },
      lable: 'EliteLearning Pro・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Live',
         'term': 'HALF_A_YEAR',
      },
      lable: 'EliteLearning Live・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Live',
         'term': 'YEARLY',
      },
      lable: 'EliteLearning Live・1 năm'
   },
   // data 10GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_10GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 10GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_10GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 10GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_10GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 10GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_10GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 10GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_10GB',
         'term': 'YEARLY',
      },
      lable: 'Data 10GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_10GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 10GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_10GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 10GB・3 năm'
   },
   // data 20GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_20GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 20GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_20GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 20GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_20GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 20GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_20GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 20GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_20GB',
         'term': 'YEARLY',
      },
      lable: 'Data 20GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_20GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 20GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_20GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 20GB・3 năm'
   },
   // data 30GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_30GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 30GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_30GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 30GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_30GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 30GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_30GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 30GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_30GB',
         'term': 'YEARLY',
      },
      lable: 'Data 30GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_30GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 30GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_30GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 30GB・3 năm'
   },
   // data 40GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_40GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 40GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_40GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 40GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_40GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 40GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_40GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 40GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_40GB',
         'term': 'YEARLY',
      },
      lable: 'Data 40GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_40GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 40GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_40GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 40GB・3 năm'
   },
   // data 50GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_50GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 50GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_50GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 50GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_50GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 50GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_50GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 50GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_50GB',
         'term': 'YEARLY',
      },
      lable: 'Data 50GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_50GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 50GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_50GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 50GB・3 năm'
   },
   // data 60GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_60GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 60GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_60GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 60GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_60GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 60GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_60GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 60GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_60GB',
         'term': 'YEARLY',
      },
      lable: 'Data 60GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_60GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 60GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_60GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 60GB・3 năm'
   },
   // data 70GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_70GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 70GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_70GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 70GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_70GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 70GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_70GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 70GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_70GB',
         'term': 'YEARLY',
      },
      lable: 'Data 70GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_70GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 70GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_70GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 70GB・3 năm'
   },
   // data 80GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_80GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 80GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_80GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 80GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_80GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 80GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_80GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 80GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_80GB',
         'term': 'YEARLY',
      },
      lable: 'Data 80GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_80GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 80GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_80GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 80GB・3 năm'
   },
   // data 90GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_90GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 90GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_90GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 90GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_90GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 90GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_90GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 90GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_90GB',
         'term': 'YEARLY',
      },
      lable: 'Data 90GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_90GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 90GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_90GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 90GB・3 năm'
   },
   // data 100GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_100GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 100GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_100GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 100GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_100GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 100GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_100GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 100GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_100GB',
         'term': 'YEARLY',
      },
      lable: 'Data 100GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_100GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 100GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_100GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 100GB・3 năm'
   },
   // data 200GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_200GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 200GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_200GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 200GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_200GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 200GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_200GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 200GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_200GB',
         'term': 'YEARLY',
      },
      lable: 'Data 200GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_200GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 200GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_200GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 200GB・3 năm'
   },
   // data 300GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_300GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 300GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_300GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 300GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_300GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 300GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_300GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 300GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_300GB',
         'term': 'YEARLY',
      },
      lable: 'Data 300GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_300GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 300GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_300GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 300GB・3 năm'
   },
   // data 400GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_400GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 400GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_400GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 400GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_400GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 400GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_400GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 400GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_400GB',
         'term': 'YEARLY',
      },
      lable: 'Data 400GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_400GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 400GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_400GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 400GB・3 năm'
   },
   // data 500GB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_500GB',
         'term': 'MONTHLY',
      },
      lable: 'Data 500GB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_500GB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 500GB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_500GB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 500GB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_500GB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 500GB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_500GB',
         'term': 'YEARLY',
      },
      lable: 'Data 500GB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_500GB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 500GB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_500GB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 500GB・3 năm'
   },
   // data 1TB
   {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_1TB',
         'term': 'MONTHLY',
      },
      lable: 'Data 1TB・1 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_1TB',
         'term': 'BIMONTHLY',
      },
      lable: 'Data 1TB・2 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_1TB',
         'term': 'TRIMONTHLY',
      },
      lable: 'Data 1TB・3 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_1TB',
         'term': 'HALF_A_YEAR',
      },
      lable: 'Data 1TB・6 tháng'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_1TB',
         'term': 'YEARLY',
      },
      lable: 'Data 1TB・1 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_1TB',
         'term': 'BIENNIALLY',
      },
      lable: 'Data 1TB・2 năm'
   }, {
      value: {
         'license_type': 'NEW',
         'service_code': 'Volume_1TB',
         'term': 'TRIENNIALLY',
      },
      lable: 'Data 1TB・3 năm'
   }
]

export default Licenses;