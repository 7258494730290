import { constant } from "../../../../services/constant";

class PaginationModel {
    constructor(pageSize, pageNo, totalRows) {
        this.pageSize = pageSize || constant.DEFAULT_PAGE_SIZE;
        this.pageNo = pageNo || 1;
        this.totalRows = totalRows || 0;
    }
}

export default PaginationModel;