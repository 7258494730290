import React from 'react';
import { userServices } from '../../services/userServices';
import { Link, withRouter } from 'react-router-dom';
import { constant } from '../../services/constant';
import logo from '../../assets/images/logo.svg'
import { Toast } from '../../utils/Toast';

class Login extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         email: '',
         password: '',
         showPass: false,
         emailError: false,
         passwordError: false,
         isSubmitting: false,
      }
   };

   handleShowHidePass = () => {
      this.setState({showPass: !this.state.showPass})
   }

   handleInputChange = (e) => {
      this.setState({
         [e.target.id]: e.target.value,
      })
      this.validateInput(e.target.id, e.target.value)
   }

   validateInput = (field, value) => {
      if (field === void 0 && value === void 0) {
         let allowSubmit = false;
         let emailError = this.validateInput('email', this.state.email)
         let passwordError = this.validateInput('password', this.state.password)
         if (emailError && passwordError) {
            allowSubmit = true;
         }
         return allowSubmit;
      }
      if (field === 'email') {
         if (value.trim() === '') {
            this.setState({emailError: "Vui lòng nhập email"});
            return false;
         } else if ((/\S+@\S+\.\S+/.test(value)) === false) {
            this.setState({
               emailError: 'Email không hợp lệ',
            });
            return false;
         } else {
            this.setState({emailError: ""})
         }
      } else if (field === 'password') {
         if (value === '') {
            this.setState({passwordError: "Vui lòng nhập mật khẩu"});
            return false;
         } else {
            this.setState({passwordError: ""});
         }
      }
      return true;

   }

   handleSubmit = async (e) => {
      e.preventDefault();
      console.log(this.state.email, this.state.password)
      if (this.validateInput()) {
         await this.setState({isSubmitting: true})
         let data = {
            "email": this.state.email.trim(),
            "password": this.state.password
         }
         let [success, body] = await userServices.login(data)
         if (success) {
            localStorage.setItem('token', body.data.token.access_token)
            this.props.history.push('/');
         }
      }
      this.setState({isSubmitting: false})
   };

   render() {
      return (
         <div className='login-container'>
            <form onSubmit={this.handleSubmit} className="login-content">
               <img src={logo} width='327px' height='32px' alt='' />
               <p className='login'>Đăng nhập</p>
               <div className="title-input">Email<span className="ic-start">*</span></div>
               <input
                  className="field-input"
                  placeholder='Email'
                  id="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
               />
               {this.state.emailError && <div className="message-error">{this.state.emailError}</div>}
               <div className="d-flex align-items-center">
                  <div className="title-input">Mật khẩu<span className="ic-start">*</span></div>
                  {/* <Link to={constant.FORGOT_PASSWORD} className='forgot-pass title-input'>Quên mật khẩu</Link> */}
               </div>
               <div className='pass-input'>
                  <input
                     className="field-input"
                     type={this.state.showPass ? "text" : "password"}
                     id="password"
                     placeholder='Mật khẩu'
                     value={this.state.password}
                     onChange={this.handleInputChange}
                  />
                  <i
                     className={`ic-16 ${this.state.showPass ? 'ic-hide-pass' : 'ic-show-pass'}`}
                     onClick={this.handleShowHidePass}
                  />
                  {this.state.passwordError && <div className="message-error">{this.state.passwordError}</div>}
               </div>
               <button className="login-button" disabled={this.state.isSubmitting}>Đăng nhập</button>
            </form>
         </div>
      )
   }
}
export default withRouter(Login);