import React from 'react';
import moment from "moment-timezone";
import IconAvatar from '../Icons/IconAvatar';
import IconPhoto from '../Icons/IconPhoto';
import { saleServices } from '../../services/saleServices';
import { userServices } from '../../services/userServices';
import { API_CONST } from '../../Api';
import { Toast } from '../../utils/Toast';

class UserInfo extends React.Component{
    constructor(props){
        super();
        this.state = {
            data: [],
            name: '',
            errored: false,
            isSubmitting: false,
        }
        this.uploadFile = React.createRef();
    }

    componentDidMount() {
        this.getUserProfile();
    }

    getUserProfile = async () => {
        let [success, body] = await userServices.getUserProfile();
        if (success === true) {
            console.log(body)
            this.setState({
                data: body.data,
                name: body.data.full_name,
            })
        }
    }

    chooseAvatar = async () => {
        let fieldInput = this.uploadFile.current;
        if(fieldInput){
            let file = fieldInput.files[0];
            let formData = new FormData();
            if (this.state.data.email && file) {
                formData.append('avatar', file);
                formData.append('full_name', this.state.name);
                let [success, body] = await saleServices.updateInfoUser(this.state.data.email, formData);
                if (success) {
                    await Toast.Info('Thay đổi avatar thành công')
                    this.getUserProfile();
                }
            }
        }
    }

    handleChange = async (e) => {
        e.preventDefault();
        let data = this.state.data;
        let formData = new FormData();
        if (data) {
            await this.setState({isSubmitting: true})
            formData.append('full_name', this.state.name);
            let [success, body] = await saleServices.updateInfoUser(data.email, formData);
            if (success) {
                await Toast.Info('Cập nhật thông tin thành công')
            }
        }
        this.setState({isSubmitting: false})
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    addDefaultSrc = (ev) => {
        // ev.target.src = common.defaultLogo;
        // ev.target.onerror = null;
    }

    render() {
        const {data} = this.state;
        return (
            <div className='password-tab login-container'>
                {
                    data &&
                    <form onSubmit={this.handleChange} className='login-content'>
                        <div className="avatar-container">
                            <input 
                                type='file'
                                className='d-none'
                                id='upload-avatar'
                                ref={this.uploadFile}
                                onChange={this.chooseAvatar}
                                accept="image/png, image/jpeg, image/svg"
                            />
                            <label htmlFor='upload-avatar' className='avatar'>
                                {
                                    data.avatar_url ? 
                                    <img 
                                        src={data.avatar_url}
                                        alt=''
                                        className='avatar-image'
                                        onError={(e) => this.addDefaultSrc(e)}
                                    />
                                    :
                                    <IconAvatar/>
                                }
                                <div className='background-hover'>
                                    <IconPhoto/>
                                </div>
                            </label>
                        </div>
                        <div className="title-input">Email</div>
                        <input 
                            disabled
                            className="field-input"
                            value={data.email}
                        />
                        <div className="title-input">Tên</div>
                        <input 
                            className="field-input"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleInputChange}
                        />
                        <div className="title-input">Ngày tạo</div>
                        <input 
                            disabled
                            className="field-input"
                            value={moment(data.joined_at).format('DD/MM/YYYY')}
                        />
                        <button className='login-button' disabled={this.state.isSubmitting}>Lưu thay đổi</button>
                    </form>
                }
            </div>
        )
    }
}

export default UserInfo;