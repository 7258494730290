import React, { PureComponent } from "react";
import UserTypeContext from "../context/UserTypeContext";

const withUserType = (WrappedComponent) => {
    return class UserTypeHOC extends PureComponent {
        render() {
            return (
                <UserTypeContext.Consumer>
                {context => {
                    return <WrappedComponent
                        {...this.props}
                        userType={context.userType}
                        email={context.email}
                    />
        }}
                </UserTypeContext.Consumer>
            )
        }
    }
}

export default withUserType;