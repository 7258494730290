import React, { PureComponent } from 'react';
import SingleCheckbox from '../../utils/Components/SingleCheckbox';

class CheckboxSelectRow extends PureComponent {

    handleChange = (checked) => {
        this.props.onChange(this.props.name, checked);
    }

    render() {
        return (
            <SingleCheckbox
                name={this.props.name}
                label={this.props.label}
                checked={this.props.checked}
                onChange={this.handleChange}
            />
        );
    }
}

export default CheckboxSelectRow;