import React, { PureComponent } from 'react';

class IconSaleList extends PureComponent {
    render() {
        return (
            <svg 
                className="ic-points"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                d="M6.447 13.965l1.042-4.86.13-.605H3.3c-.255 0-.531-.27-.42-.663L4.868.887C5.009.631 5.246.5 5.4.5h3.8c.255 0 .53.269.42.66l-1.1 3.698-.19.642H12c.178 0 .347.111.44.299.094.187.072.365-.024.48l-.006.008-.005.006-5.958 7.672z"
                fill="#ACACAC"
                stroke="#ACACAC"
                />
            </svg>
        );
    }
}

export default IconSaleList;