import React, { PureComponent } from "react";
import InstructorContext from "../context/InstructorContext";

const withInstructor = (WrappedComponent) => {
    return class InstructorHOC extends PureComponent {
        render() {
            return (
                <InstructorContext.Consumer>
                {context => (
                    <WrappedComponent
                        {...this.props}
                        showMenu={context.showMenu}
                        toggleMenu={context.toggleMenu}
                    />
                )}
                </InstructorContext.Consumer>
            )
        }
    }
}

export default withInstructor;