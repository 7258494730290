import moment from "moment-timezone";
import { constant } from "../services/constant";

const defaultLogo = require('../assets/icons/defaultLogo.svg');

const convertToDateTime = (ISOString, dateTimeFormat, timeZone) => {
    let stillUtc = moment.utc(ISOString).toDate();
    return moment(stillUtc).tz(timeZone || constant.defaultTimeZone).format(dateTimeFormat || constant.dateTimeFormat);
}

const convertToDateTimeForSale = (dateTime) => {
    return moment(dateTime).format('DD/MM/YYYY HH:mm');
}

const convertDayMonthYear = (dateTime) => {
    return moment(dateTime).format('DD/MM/YYYY');
}

const convertToDate = (ISOString, dateFormat, timeZone) => {
    let stillUtc = moment.utc(ISOString).toDate();
    return moment(stillUtc).tz(timeZone || constant.defaultTimeZone).format(dateFormat || constant.dateFormat);
}

const generateId = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const common = {
    defaultLogo,
    convertToDateTime,
    convertToDateTimeForSale,
    convertDayMonthYear,
    convertToDate,
    generateId,
}