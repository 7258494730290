import React, { PureComponent } from 'react';

class IconAdd extends PureComponent {
    render() {
        return (
            <svg
                className='icon-remove'
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14 2L2 14M2 2l12 12"
                    stroke="#FF5F73"
                    strokeMiterlimit={10}
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default IconAdd;