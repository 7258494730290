import React, { PureComponent, memo } from 'react';
import _ from 'lodash';
import PaginationModel from './Models/PaginationModel';
import { constant } from '../../../services/constant';

class Pagination extends PureComponent {
    state = {
        from: 0,
        to: 0,
        totalPages: 0,
        pageValue: 1,
    }

    componentDidMount() {
        this.loadPagination();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.pagination, this.props.pagination)) {
            this.loadPagination();
        }
    }

    loadPagination = () => {
        const { pagination } = this.props;
        if (pagination instanceof PaginationModel) {
            let total = pagination.totalRows;
            let from = pagination.pageSize * (pagination.pageNo - 1) + 1
            if (total === 0) {
                from = 0;
            }
            let to = (pagination.pageSize * pagination.pageNo) > total ? total : (pagination.pageSize * pagination.pageNo);
            let totalPages = Math.ceil(total / pagination.pageSize);
            this.setState({
                from: from,
                to: to,
                totalPages: totalPages,
                pageValue: Math.round(pagination.pageNo),
            })
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            const { pagination } = this.props;
            const { pageValue } = this.state;
            let totalPage = Math.ceil(pagination.totalRows / pagination.pageSize);
            if (pageValue >= 1 && pageValue <= totalPage) {
                let newPagination = new PaginationModel(pagination.pageSize, pageValue, pagination.totalRows);
                this.props.onPaginate(newPagination);
            }
        }
    }

    goPreviousPage = () => {
        const { pagination } = this.props;
        if (pagination.pageNo > 1) {
            let newPagination = new PaginationModel(pagination.pageSize, pagination.pageNo - 1, pagination.totalRows);
            this.props.onPaginate(newPagination);
        }
    }

    goNextPage = () => {
        const { pagination } = this.props;
        let pageEnd = Math.ceil(pagination.totalRows / pagination.pageSize);
        if (pagination.pageNo < pageEnd) {
            let newPagination = new PaginationModel(pagination.pageSize, pagination.pageNo + 1, pagination.totalRows);
            this.props.onPaginate(newPagination);
        }
    }

    selectAllText = (e) => {
        e.target.select();
    }

    changePageInput = (e) => {
        let value = e.target.value;
        if (value !== '') {
            value = Math.round(Number(value));
        }
        this.setState({
            pageValue: value,
        });
    }

    render() {
        const { pagination, unit } = this.props;
        const { from, to, totalPages, pageValue } = this.state;
        let isInputError = totalPages > 0 ? !(pageValue >= 1 && pageValue <= totalPages) : !(pageValue === 1);
        return (
            <div className="common-pagination">
                <div className="pagination-page-size">
                {unit && <span>{unit}:</span>}{`${from} - ${to} của ${pagination.totalRows}`}
                </div>
                <div className="pagination-control">
                <button onClick={this.goPreviousPage} className="btn-control-paginate btn-prev" disabled={pagination.pageNo <= 1}>
                    <IconNext />
                </button>
                <div className="auto-resize-input">
                    <label className="auto-resize-label">{pageValue}</label>
                    <input type="number" size="1" className={`input-control-pagination ${isInputError ? 'error' : ''}`} value={pageValue} onChange={this.changePageInput} onKeyDown={this.handleKeyDown} onFocus={this.selectAllText} />
                </div>
                <button onClick={this.goNextPage} className="btn-control-paginate" disabled={pagination.pageNo >= totalPages}>
                    <IconNext />
                </button>
                </div>

                <div className="pagination-pages"><span className="describe-label">Trang:</span>{pagination.pageNo}/{totalPages}</div>
            </div>
        );
    }
}

const IconNext = memo(() => {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M5 2L11 8L5 14" stroke={constant.colorText1} strokeLinejoin="round" />
        </svg>
    );
});

export default Pagination;