import React from 'react';
import { ToastContainer, toast as defaultToast } from 'react-toastify';
import { Slide, Zoom } from 'react-toastify';
import IconError from '../../view/Icons/IconError';

const renderContainer = () => {
    return (
        <ToastContainer
            className="simple-toast-container"
            closeButton={false}
            transition={Zoom}
            enableMultiContainer
        // newestOnTop
        />
    )
}

// Default toast
const toast = (message, delay) => {
    defaultToast(
        <div className="simple-toast-content">
            <span>{message}</span>
        </div>,
        {
            className: 'simple-toast',
            bodyClassName: 'simple-toast-body',
            position: 'bottom-center',
            autoClose: delay || 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: false,
        }
    )
}

toast.info = (message, delay) => {
}

toast.error = (message, delay) => {
    defaultToast(
        <div className="simple-toast-content">
            <IconError />
            <span>{message}</span>
        </div>,
        {
            className: 'simple-toast',
            bodyClassName: 'simple-toast-body',
            position: 'bottom-center',
            autoClose: delay || 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: false,
        }
    )
}

// Remove a toast (id or ref), or remove all toast
toast.dismiss = (toastId) => {
    defaultToast.dismiss(toastId || null);
}

export {
    renderContainer,
    toast,
}