import React, { PureComponent } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { toast } from './SimpleToast';
import _get from 'lodash/get';

class ExportExcel extends PureComponent {

   handleGetData = (rowData, col) => {
      return _get(rowData, col.name);
   }

   export = () => {
      const { data, fileName, fileFormatter } = this.props;
      if (fileFormatter && data) {
         try {
            let dataFormatter = data.map((row) => {
               let rowData = {};
               for (const column of fileFormatter) {
                  let columnData = '';
                  if (column.columnRender) {
                     columnData = column.columnRender(this.handleGetData(row, column));
                  } else {
                     columnData = this.handleGetData(row, column);
                  }
                  rowData[column.header] = columnData;
               }
               return rowData;
               
            });

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileExtension = '.xls';

            const ws = XLSX.utils.json_to_sheet(dataFormatter);
            let wscols = [];
            for (let i = 0; i < fileFormatter.length; i++) {
               wscols.push({
                  width: 35,
               });
            }
            ws['!cols'] = wscols;

            const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ['Sheet 1'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xls', type: 'array' });

            const exportedData = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(exportedData, fileName + fileExtension);
         } catch (err) {
            toast.error('Đã xảy ra lỗi. Không thể xuất file.')
         }
      }
   }

   render() {
      const { className, data, fileName, children, innerRef, fileFormatter, ...props } = this.props;
      return (
         <button
            {...props}
            ref={innerRef}
            className={`${className || ''} btn-export`}
            onClick={this.export}
         >
            {children}
         </button>
      );
   }
}

export default React.forwardRef((props, ref) => <ExportExcel
   innerRef={ref} {...props}
/>);