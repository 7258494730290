const ROUTE_PROFILE = '/profile';
const ROUTE_PROFILE_INFO = '/profile/info';
const ROUTE_PROFILE_PASSWORD = '/profile/password';
const ROUTE_CHANGE_PASSWORD = '/change-password';
const ROUTE_LOGIN = '/login';
const HASH_LOGIN = '#login';
const FORGOT_PASSWORD = '/forgot-password';
const ROUTE_CUSTOMER_LIST = '/customers';
const EMAIL_PARAM = 'email';
const ROUTE_CUSTOMER_DETAIL = `/customers/:${EMAIL_PARAM}`;
const ROUTE_LICENSES = '/licenses';
const ROUTE_SALES = '/sales';
const ROUTE_STUDENTS = '/students';

const defaultTimeZone = 'Asia/Ho_Chi_Minh';
const dateTimeFormat = 'DD/MM/YYYY HH:MM';
const colorText1 = '#111111';
const DEFAULT_PAGE_SIZE = 500;
const prevPageParam = 'prev-page';

export const constant = {
    ROUTE_PROFILE,
    ROUTE_PROFILE_INFO,
    ROUTE_PROFILE_PASSWORD,
    ROUTE_CHANGE_PASSWORD,
    ROUTE_LOGIN,
    HASH_LOGIN,
    FORGOT_PASSWORD,
    ROUTE_CUSTOMER_LIST,
    ROUTE_CUSTOMER_DETAIL,
    ROUTE_LICENSES,
    ROUTE_SALES,
    ROUTE_STUDENTS,

    EMAIL_PARAM,
    defaultTimeZone,
    dateTimeFormat,
    colorText1,
    DEFAULT_PAGE_SIZE,
    prevPageParam,
}