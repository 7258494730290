import React, { Fragment, PureComponent } from 'react'
import { Col, Row } from 'reactstrap';
import { saleServices } from '../../services/saleServices';
import ClonedItem from '../../utils/Components/Common/ClonedItem';
import { toast } from '../../utils/Components/SimpleToast';
import ModalCommon from '../Common/ModalCommon';
import IconClose from '../Icons/IconClose';
import IconDown from '../Icons/IconDown';
import Select, { components } from 'react-select';


const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
        <IconDown/>
        </components.DropdownIndicator>
    );
};

export default class TransferCustomer extends PureComponent {
    state = {
        sales: [],
        openConfirmation: false,
        submitting: false,
        dropdownOpen: false,
        emailSelected: '',
    }

    toggleConfirmModal = () => {
        this.setState({
            openConfirmation: !this.state.openConfirmation,
        });
    }

    handleSelect = (data) => {
        this.setState({
            emailSelected: data.value,
        })
    }

    transferCustomer = async () => {
        await this.setState({ submitting: true });
        if (this.props.selectedData && this.props.selectedData.length !== 0) {
            let email = this.props.selectedData;
            let body = {
                'salesclerk_email': this.state.emailSelected,
            }
            let [success, response] = await saleServices.transferCustomer(email, body);
            if(success) {
                this.toggleConfirmModal();
                setTimeout(() => {
                    this.props.onSuccess();
                }, 100);
                await toast(`Đã chuyển khách hàng “${email}” cho sale ${this.state.emailSelected}`);
            }
        }
        this.setState({ submitting: false });

    }

    render() {
        const { children, sales, selectedCustomer } = this.props;
        return (
            <Fragment>
                {
                React.Children.map(children,
                    child => {
                        return (
                            <ClonedItem
                            child={child}
                            onClick={this.toggleConfirmModal}
                            />
                        )
                    }
                )
                }
                <ModalCommon isOpen={this.state.openConfirmation} toggle={this.toggleConfirmModal}>
                <div className="common-modal-header">
                    <label>Đổi quản lý</label>
                    <div className="circle-button" onClick={this.toggleConfirmModal}><IconClose /></div>
                </div>
                <div className="common-modal-body">
                    {
                        this.props.selectedData.length === 1 ? (
                            <span>Transfer khách hàng “{this.props.selectedData[0]}” cho sale:</span>
                        ) : (
                            <span>Những khách hàng được chọn sẽ được transfer.</span>
                            )
                    }
                    <Row className="input-row license-dropdown">
                        <Col md={8}>
                            <Select
                                onChange={this.handleSelect}
                                options = {
                                        sales.filter(item => item.email !== selectedCustomer.salesclerk_email).map(item => {
                                                return (
                                                    {
                                                        value: item.email,
                                                        label: <div className='title'>{item.email}</div>
                                                    }
                                                )
                                        })
                                }
                                components={{DropdownIndicator}}
                                className='elite-select'
                                classNamePrefix='elite-sale'
                            />
                        </Col>
                    </Row>
                    <div className="modal-actions">
                        <button className="btn-cancel" onClick={this.toggleConfirmModal}>Đóng</button>
                        <button className="btn-active bg-orange" onClick={this.transferCustomer} disabled={this.state.submitting}>Transfer</button>
                    </div>
                </div>
                </ModalCommon>
            </Fragment>
        );
    }
}
