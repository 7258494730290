import React, { PureComponent } from 'react';

class IconError extends PureComponent {
    render() {
        return (
            <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.8629 13.4952L8.86382 1.49473C8.47796 0.833138 7.52205 0.833137 7.13619 1.49473L0.137079 13.4952C-0.251739 14.1619 0.229139 14.999 1.0009 14.999H14.9991C15.7709 14.999 16.2517 14.1619 15.8629 13.4952ZM7.25 5.00974H8.75V10.0049H7.25V5.00974ZM8 13.0019C8.55229 13.0019 9 12.5547 9 12.0029C9 11.4512 8.55229 11.0039 8 11.0039C7.44772 11.0039 7 11.4512 7 12.0029C7 12.5547 7.44772 13.0019 8 13.0019Z"
                    fill="#FF5F73"
                />
                </g>
            </svg>
        );
    }
}

export default IconError;