import React, { Fragment, PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import InstructorContext from '../../context/InstructorContext';
import { constant } from '../../services/constant';
import LeftMenuContainer from '../Common/LeftMenuContainer';
import CreateCustomer from '../Customer/CreateCustomer';
import CustomerDetail from '../Customer/CustomerDetail';
import Customers from '../Customer/Customers';
import Avatar from '../Profile/Avatar';
import CreateSale from '../Sale/CreateSale';
import Licenses from '../Sale/Licenses';
import Sales from '../Sale/Sales';

class MainContent extends PureComponent {
    state = {
        showMenu: false,
        courseName: '',
        org: '',
        number: '',
        hasCertificate: false,
    }

    toggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu });
    }

    render() {
        return (
            <InstructorContext.Provider value={{
                showMenu: this.state.showMenu,
                toggleMenu: this.toggleMenu,
                hasCertificate: this.state.hasCertificate,
            }}>
                <LeftMenuContainer
                    showMenu={this.state.showMenu}
                    toggleMenu={this.toggleMenu}
                    courseName={this.state.courseName}
                    org={this.state.org}
                    number={this.state.number}
                />
                <Fragment>
                    <Switch>
                        <Redirect exact from={constant.ROUTE_PROFILE} to={constant.ROUTE_PROFILE_INFO} />
                        <Route path={constant.ROUTE_PROFILE} component={Avatar} />
                        <Route path='/create-new-sale' component={CreateSale} />
                        <Route path='/create-new-customer' component={CreateCustomer} />
                        <Route exact path={constant.ROUTE_CUSTOMER_LIST} 
                            render = { () => {
                                    return <Customers
                                        roleName = {this.props.roleName}
                                    />
                                }
                            }
                        />
                        <Route exact path={constant.ROUTE_STUDENTS}
                            render={() => {
                                return <Customers
                                    roleName={this.props.roleName}
                                />
                            }
                            }
                        />
                        <Route path={constant.ROUTE_SALES} component={Sales} />
                        <Route exact path={constant.ROUTE_CUSTOMER_DETAIL} component={CustomerDetail} />
                        <Route path={constant.ROUTE_LICENSES} component={Licenses} />
                    </Switch>
                </Fragment>
            </InstructorContext.Provider>
        )
    }
}

export default MainContent;