import React, { PureComponent, Fragment } from 'react';
import SuperRowColumn from './SuperRowColumn';

class SuperTableRow extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            canDrag: false,
        }
    }

    onRightClick = (e) => {
        e.preventDefault();
        return false;
    }

    renderARow = () => {
        const { row, columns, rowIndex, draggable, isActive } = this.props;
        const { canDrag } = this.state;
        return (
            <tr className={`super-table-row ${isActive ? 'active' : ''}`} draggable={draggable && canDrag}
            onDragStart={this.onDragStart}
            onContextMenu={this.onRightClick}
            >
            {
                columns.map((col, index) => {
                    let isFixedLeft = col.fixedLeft;
                    let isFixedLeftLast = col.fixedLeft && (index !== (columns.length - 1)) && !columns[index + 1].fixedLeft;
                    let isFixedRight = col.fixedRight;
                    let isFixedRightLast = col.fixedRight && (index !== 0) && !columns[index - 1].fixedRight;
                    return (
                        <td
                        className={`super-col ${index === 0 ? 'first-col' : ''} ${index === (columns.length - 1) ? 'last-col' : ''} ${col.className || ''} ${isFixedLeft ? 'col-fixed-left' : ''} ${isFixedLeftLast ? 'col-fixed-left-last' : ''} ${isFixedRight ? 'col-fixed-right' : ''} ${isFixedRightLast ? 'col-fixed-right-last' : ''}`}
                        key={`${col.name}_${index}`}
                        >
                        <SuperRowColumn
                            {...this.props}
                            {...this.state}
                            col={col}
                            rowData={row}
                            rowIndex={rowIndex}
                        />
                        </td>
                    )
                })
            }
            </tr >
        )
    }

    render() {
        return (
            <Fragment>
            {this.renderARow()}
            </Fragment>
        );
    }
}

export default SuperTableRow;