import React, { PureComponent } from 'react';

class IconAvatar extends PureComponent {
    render() {
        return (
            <svg
                className='icon-upload'
                width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M45.0348 0.0476392C21.4224 -1.05692 1.17208 17.2799 0.0487064 40.9621C-1.07198 64.6444 17.2836 84.8275 40.9686 85.9509C41.6566 85.9858 42.3392 85.9993 43.0245 85.9993C65.8065 85.9993 84.8608 68.0253 85.9546 45.0337C87.0726 21.3515 68.717 1.17101 45.0348 0.0476392ZM41.2212 80.5813C32.1805 80.1513 24.032 76.5473 17.7943 70.9036C19.2428 64.6283 23.8546 62.274 30.1756 60.167C34.1262 58.8501 36.0397 55.5096 36.975 52.5695C31.0598 50.1749 26.8753 44.3833 26.8753 37.6243V32.2493C26.8753 23.3563 34.1074 16.1243 43.0003 16.1243C51.8933 16.1243 59.1253 23.3563 59.1253 32.2493V37.6243C59.1253 44.386 54.9382 50.1803 49.023 52.5721C49.9583 55.5123 51.8771 58.8501 55.8278 60.167C62.1488 62.274 66.7256 64.6551 68.1634 70.9358C61.0415 77.3616 51.4982 81.0919 41.2212 80.5813Z" fill="#EBEBEB"/>
            </svg>
        );
    }
}

export default IconAvatar;