import React, { PureComponent } from 'react';

class IconDown extends PureComponent {
    render() {
        return (
            <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M5.20711 6C4.76165 6 4.53857 6.53857 4.85355 6.85355L7.64645 9.64645C7.84171 9.84171 8.15829 9.84171 8.35355 9.64645L11.1464 6.85355C11.4614 6.53857 11.2383 6 10.7929 6H5.20711Z"
                fill="#ACACAC"
                />
            </svg>
        );
    }
}

export default IconDown;