import React from 'react';
import MainContent from './MainContent';
import { userServices } from '../../services/userServices';
import { renderContainer } from '../../utils/Components/SimpleToast';
import UserTypeContext from '../../context/UserTypeContext';
class DefaultLayout extends React.Component {
    constructor(props){
        super();
        this.state = {
            loading: false,
            roleName: '',
            email: '',
            userType: '',
        }
    }

    componentDidMount(){
        let token = localStorage.getItem('token');
        if(!token){
            this.props.history.push('/login')
        } else {
            this.getUserProfile();
        }
    };

    getUserProfile = async () => {
        let [success, body] = await userServices.getUserProfile();
        if (success === true) {
            this.setState({ 
                loading: true,
                roleName: body.data.role_name,
                email: body.data.email,
                userType: body.data.user_type,
            })
        }
    }
    render(){
        return(
            <UserTypeContext.Provider value={{
                email: this.state.email,
                userType: this.state.userType,
            }}
            >
            <div>
                {
                    this.state.loading && 
                    <div>
                        {/* <DefaultHeader /> */}
                        <MainContent
                            roleName = {this.state.roleName}
                        />
                        {renderContainer()}
                        {/* <DefaultFooter /> */}
                    </div>
                }
            </div>
            </UserTypeContext.Provider>
        )
    }
}
export default DefaultLayout;