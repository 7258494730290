import React, { PureComponent, Fragment} from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CreateCustomer from '../Customer/CreateCustomer';
import CreateSale from '../Sale/CreateSale';
import IconAdd from '../Icons/IconAdd';
import IconUserList from '../Icons/IconUserList';
import IconSaleList from '../Icons/IconSaleList';
import IconTemplateFile from '../Icons/IconTemplateFile';
import ModalCommon from './ModalCommon';
import { serviceService } from '../../services/serviceServices';

class AddItem extends PureComponent{
    state = {
        dropdownOpen: false,
        type: '',
        templateFile: '',
    };

    dropdownToggle = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    }

    handleToggle = () => {
        if(this.props.handleToggle){
            this.props.handleToggle();
        }
    }

    openModal = (type) => {
        this.setState({type}, ()=>{
            this.handleToggle();
        })
    }

    showModal = () => {
        if(this.state.type === 'add-customer'){
            return(
                <CreateCustomer
                handleToggle = {this.handleToggle}
                reloadData = {this.props.reloadData}
                />
            )
        } else if (this.state.type === 'add-sale'){
            return(
                <CreateSale
                handleToggle = {this.handleToggle}
                reloadData = {this.props.reloadData}
                />
            )
        }
    }

    componentDidMount () {
        this.getTemplateFile();
    }

    getTemplateFile = async () => {
        let [success, body] = await serviceService.getTemplateFile();
        if(success){
            this.setState({templateFile: body.data})
        }
    }

    render(){
        const {templateFile} = this.state;
        return(
            <Fragment>
                {
                    templateFile && 
                    <div>
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.dropdownToggle} className='dropdown-create'>
                            <DropdownToggle>
                                <IconAdd/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={()=>this.openModal('add-customer')}>
                                    <div className='add-user'><IconSaleList/></div>
                                    Thêm khách hàng
                                </DropdownItem>
                                {
                                    this.props.roleName !== "SALE_AREA" &&
                                    <DropdownItem onClick={()=>this.openModal('add-sale')}>
                                        <div className='add-user'><IconUserList/></div>
                                        Thêm nhân viên
                                    </DropdownItem> 
                                }                      
                                <DropdownItem>
                                    <div className='add-user'>
                                        <IconTemplateFile/>
                                        <a href={templateFile} target='_bank'>File hợp đồng mẫu</a>
                                    </div> 
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <ModalCommon isOpen={this.props.isOpen} toggle={this.handleToggle}>
                            {this.showModal()}
                        </ModalCommon>
                    </div>
                }
            </Fragment>
        )
    }
}
export default AddItem;