import React, { Fragment, PureComponent } from 'react';
import { common } from '../../utils/Common';
import HeaderSelectAll from '../Common/HeaderSelectAll';
import _cloneDeep from 'lodash/cloneDeep';
import _includes from 'lodash/includes';
import _merge from 'lodash/merge';
import _isEqual from 'lodash/isEqual';
import { saleServices } from '../../services/saleServices';
import SuperTable from '../../utils/Components/SuperTable/SuperTable';
import PaginationModel from '../../utils/Components/SuperTable/Models/PaginationModel';
import CheckboxSelectRow from '../Common/CheckboxSelectRow';
import ActionGroup from '../Sale/ActionGroup';
import Tooltip from '../../utils/Components/Tooltip';
import SearchBox from '../Common/SearchBox';
import LeftMenu from '../Common/LeftMenu';
import UnenrollBtn from '../Sale/UnenrollBtn';
import IconFilter from '../Icons/IconFilter';
import IconAdd from '../Icons/IconAdd';
import IconNotFound from '../Icons/IconNotFound';
import IconStudent from '../Icons/IconStudent';
import IconEmail from '../Icons/IconEmail';
import ModalCreateCustomer from './CreateCustomer';
import { Link, withRouter } from 'react-router-dom';
import AddItem from '../Common/AddItem';
import { constant } from '../../services/constant';
import CreateCustomer from './CreateCustomer';
import ModalCommon from '../Common/ModalCommon';
import SortObjModel from '../../utils/Components/SuperTable/Models/SortObjModel';
import { ReactComponent as IcLoading } from '../../assets/icons/loading.svg';
import FilterCourses from './FilterCourses';
import IconClose from '../Icons/IconClose';
import Export from './Export';
import IconStudents from '../Icons/IconStudents';
import withUserType from '../../HOC/UserTypeHOC';
import IconSync from '../Icons/IconSync';
import { toast } from '../../utils/Components/SimpleToast';

class Customers extends PureComponent {
    state = {
        data: [],
        columns: [],
        selectedData: [],
        search: '',
        pagination: new PaginationModel(),
        sortObj: new SortObjModel(),
        isOpen: false,
        startCreateOpen: false,
        filter: null,
        saleList: [],
        disableSync: false,
    }

    _isMounted = false;

    componentWillMount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.prepareColumn();
        this.loadData();
        this.getListSale();
    }

    getListSale = async () => {
        let body = {
            'manager_email': this.props.email,
            'status': 'AVAILABLE',
        };
        let [success, data] = await saleServices.getListSales(body);
        if (success) {
            this.setState({
                saleList: data.data.content,
            })
        }
    }

    dateFormatter = (cell, row, rowIndex) => {
        return common.convertToDateTimeForSale(row.joined_at);
    }

    statusFormatter = (cell, row, rowIndex) => {
        if (row.status === 'LOCKED') {
            return <div className="red-status">Locked</div>;
        }
        else if (row.status === 'AVAILABLE') {
            return <div className="green-status">Available</div>;
        }
        return row.status;
    }

    onUnenrollSuccess = () => {
        let pagination = new PaginationModel(this.state.pagination.pageSize, 1);
        this.loadData(pagination);
        this.setState({
            selectedData: [],
        }, this.prepareColumn);
    }

    actionFormatter = (cell, row, rowIndex) => {
        return <ActionGroup
            row={row}
            selectedData={[row.email]}
            courseId={this.props.match.params.courseId}
            onSuccess={this.onUnenrollSuccess}
            match={this.props.match}
            role={this.props.roleName}
            sales={this.state.saleList || []}
        />
    }

    checkSelectAll = () => {
        const { selectedData, data } = this.state;

        if (selectedData.length !== 0) {
            for (const item of data) {
                if (!_includes(selectedData, item.email)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    onCheckAll = (checked) => {
        let selectedData = _cloneDeep(this.state.selectedData);
        console.log(selectedData)
        for (const item of this.state.data) {
            if (checked && !_includes(selectedData, item.email)) {
                selectedData.push(item.email);
            } else if (!checked && _includes(selectedData, item.email)) {
                selectedData = selectedData.filter(i => i !== item.email);
            }
        }
        this.setState({
            selectedData: selectedData,
        }, this.prepareColumn);
    }

    checkARowSelected = (email) => {
        return this.state.selectedData.some(i => i === email);
    }

    onCheckARow = (email, checked) => {
        let selectedData = _cloneDeep(this.state.selectedData);
        if (checked && !selectedData.some(i => i === email)) {
            selectedData.push(email);
        } else if (!checked) {
            selectedData = selectedData.filter(i => i !== email);
        }
        this.setState({
            selectedData: selectedData,
        }, this.prepareColumn);
    }

    nameFormatter = (cell, row, rowIndex) => {
        return (
            <CheckboxSelectRow
                name={row.email}
                label={row.full_name}
                checked={this.checkARowSelected(row.email)}
                onChange={this.onCheckARow}
            />
        );
    }

    emailFormatter = (cell, row, rowIndex) => {
        return (
            <Link to={`customers/${row.email}`} className="customer-email">{row.email}</Link>
        )
    }

    checkPartialSelection = () => {
        const { selectedData, data } = this.state;
        if (selectedData.length !== 0) {
            for (const item of data) {
                if (_includes(selectedData, item.email)) {
                    return true;
                }
            }
        }
        return false;
    }

    handlePaginate = (newPagination) => {
        if (newPagination instanceof PaginationModel) {
            let pagination = _cloneDeep(newPagination);
            this.setState({
                pagination: pagination,
            });
            this.loadData(pagination);
        }
    }

    numberFormatter = (cell, row, rowIndex) => {
        let pageNo = this.state.pagination.pageNo;
        let index = rowIndex;
        if (pageNo !== 1) {
            index = (rowIndex + 1) + constant.DEFAULT_PAGE_SIZE * (pageNo - 1);
        } else {
            index = index + 1;
        }
        return index;
    }

    prepareColumn = () => {
        let columns = [];
        if (this.props.history.location.pathname === constant.ROUTE_CUSTOMER_LIST){
            let userType = this.props.userType;
            if (userType === 'REPORTER'){ // reporter ko co action
                columns = [{
                    header: 'STT',
                    cell: '',
                    formatter: this.numberFormatter,
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Tên',
                    cell: 'full_name',
                    name: 'full_name',
                    sort: true,
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Email',
                    cell: 'email',
                    formatter: this.emailFormatter,
                    headerClassName: 'content-no-wrap',
                    className: 'content-min-width',
                }, {
                    header: 'Trạng thái',
                    cell: 'status',
                    formatter: this.statusFormatter,
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Nghề nghiệp',
                    cell: 'role',
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Số điện thoại',
                    cell: 'phone_number',
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Ngày tạo',
                    cell: 'joined_at',
                    name: 'joined_at',
                    sort: true,
                    formatter: this.dateFormatter,
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Công ty',
                    cell: 'company',
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Sale quản lý',
                    cell: 'salesclerk_email',
                    headerClassName: 'content-no-wrap',
                }]
            } else {
                columns = [{
                    header: 'STT',
                    cell: '',
                    formatter: this.numberFormatter,
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Tên',
                    cell: 'full_name',
                    name: 'full_name',
                    sort: true,
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Email',
                    cell: 'email',
                    formatter: this.emailFormatter,
                    headerClassName: 'content-no-wrap',
                    className: 'content-min-width',
                }, {
                    header: 'Trạng thái',
                    cell: 'status',
                    formatter: this.statusFormatter,
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Nghề nghiệp',
                    cell: 'role',
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Số điện thoại',
                    cell: 'phone_number',
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Ngày tạo',
                    cell: 'joined_at',
                    name: 'joined_at',
                    sort: true,
                    formatter: this.dateFormatter,
                    headerClassName: 'content-no-wrap',
                    className: 'content-min-width',
                }, {
                    header: 'Công ty',
                    cell: 'company',
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Sale quản lý',
                    cell: 'salesclerk_email',
                    headerClassName: 'content-no-wrap',
                }, {
                    header: 'Hành động',
                    headerClassName: 'header-action',
                    className: 'column-action custom-col-action',
                    formatter: this.actionFormatter,
                }]
            }
        } else {
            columns = [{
                header: 'STT',
                cell: '',
                formatter: this.numberFormatter,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Tên',
                cell: 'full_name',
                name: 'full_name',
                sort: true,
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Email',
                cell: 'email',
                formatter: this.emailFormatter,
                headerClassName: 'content-no-wrap',
                className: 'content-min-width',
            }, {
                header: 'Ngày tạo',
                cell: 'joined_at',
                name: 'joined_at',
                sort: true,
                formatter: this.dateFormatter,
                headerClassName: 'content-no-wrap',
                className: 'content-min-width',
            },{
                header: 'Nghề nghiệp',
                cell: 'role',
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Số điện thoại',
                cell: 'phone_number',
                headerClassName: 'content-no-wrap',
            }, {
                header: 'Công ty',
                cell: 'company',
                headerClassName: 'content-no-wrap',
            }]
        }
        this.setState({
            columns: columns
        });
    }

    loadData = async (pagination, searchValue, sortObj) => {
        if (!this._isMounted) {
            return;
        }
        let search = this.state.search;
        if (searchValue !== void 0 && searchValue !== null) {
            search = searchValue;
        }
        let newPagination = _cloneDeep(this.state.pagination);
        if (pagination) {
            newPagination = pagination;
        }
        await this.setState({ isLoading: true });
        let body = {
            "page": newPagination.pageNo,
            "page_size":  newPagination.pageSize,
            // "search": searchValue,
            "search": search,
            "sort_type": "DESC",
            "order_by": "joined_at", // String - optional
        }
        if (this.props.history.location.pathname === constant.ROUTE_CUSTOMER_LIST){
            // body.is_customer = true;
            body.role = "TEACHER";
            if (this.props.userType !== 'REPORTER' && this.props.userType !== 'SYSTEM'){
                body.salesclerk_email = this.props.email;
            }
        } else if (this.props.history.location.pathname === constant.ROUTE_STUDENTS){
            body.role = "LEARNER";
            // body.is_active = false;
        }
        if (this.state.filter) {
            body = _merge(body, {
                "from_date": this.state.filter['start'],
                "to_date": this.state.filter['end'],
            })
        }
        let sort = _cloneDeep(this.state.sortObj);
        if(sortObj && sortObj instanceof SortObjModel){

            sort = sortObj;
            body.sort_type = sort.sortType;
            body.order_by = sort.orderBy;
        }
        let [isSuccess, response] = [];
        if (this.props.history.location.pathname === constant.ROUTE_CUSTOMER_LIST) {
            [isSuccess, response] = await saleServices.getListCustomers(body);
        } else if (this.props.history.location.pathname === constant.ROUTE_STUDENTS) {
            [isSuccess, response] = await saleServices.getStudentsList(body);
        }
        if (this._isMounted) {
            let data = [];
            if (isSuccess) {
                if (response && response.data) {
                    console.log(response.data)
                    data = response.data.content || [];
                    // thêm field is_customer để xác định có phải là customer không
                    if (data.length !== 0) {
                        for (let i of data) {
                            i.is_customer = true
                        }
                    }
                    newPagination = new PaginationModel(response.data.pageSize, response.data.currentPage, response.data.totalRows);
                }
            }
            if (!_isEqual(data, this.state.data)) {
                await setTimeout(() => {
                    this.setState({
                    data: data,
                    pagination: newPagination,
                    search: searchValue || '',
                    isLoading: false,
                    sortObj: sort,
                    });
                }, 300);
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        }
    }

    deselectAll = () => {
        this.onCheckAll(false);
    }

    openCreateCustomer = () => {
        this.setState({
            isOpen: true
        })
    }

    handleToggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    startCreateOpen = () => {
        this.setState({
            startCreateOpen: !this.state.startCreateOpen
        })
    }

    handleSearch = (value) => {
        let pagination = new PaginationModel(this.state.pagination.pageSize, 1);
        this.loadData(pagination, value)
    }

    removeFilter = () => {
        this.setState({
            search: '',
        }, () => {
            let pagination = new PaginationModel(this.state.pagination.pageSize, 1);
            this.loadData(pagination, '')
        })
    }

    // Filter component
    filter = (filterObj) => {
        let newFilter = _cloneDeep(filterObj);
        if (!newFilter.start && !newFilter.end) {
            newFilter = null;
        }
        this.setState({
            filter: newFilter,
        });
        this.loadData();
    }

    clearFilter = () => {
        this.filter({});
    }

    handleSortData = (sortObj) => {
        if(sortObj instanceof SortObjModel){
            let pagination = new PaginationModel(this.state.pagination.pageSize, 1);
            this.loadData(pagination, '', sortObj);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.history.location.pathname){
            let pagination = new PaginationModel();
            this.loadData(pagination, '', '');
            this.prepareColumn();
        }
    }

    syncData = async () => {
        this.setState({ disableSync: true});
        const [isSuccess, response] = await saleServices.syncData();
        if(isSuccess) {
            toast('Sync data success')
        } else {
            toast.error('Sync data fail')
        }
        this.setState({ disableSync: false });
    }

    render() {
        console.log(this.props.userType)
        return (
            <Fragment>
                {
                    this.state.isLoading &&
                    <div className="content-loading">
                        <IcLoading />
                    </div>
                }
                <div className="instructor-header">
                    <div className="left-group">
                        <LeftMenu />
                        <span className="page-name disable-select">
                            {
                                this.props.history.location.pathname === constant.ROUTE_CUSTOMER_LIST ?
                                " Danh sách Khách hàng"
                                :
                                "Danh sách học viên"
                            }
                        </span>
                    </div>
                    <div className="center-container">
                        <SearchBox
                            value={this.state.search}
                            placeholder="Tên hoặc email"
                            onSearch={this.handleSearch}
                        />
                    </div>
                    <div className="right-group">
                        
                        {
                            this.props.userType === "SYSTEM" &&
                            <Tooltip title="Sync data">
                                {
                                    this.state.disableSync ? 
                                        <div className={`header-button syncing`}
                                        ><IconSync /></div>
                                        :
                                        <div className={`header-button button-sync`}
                                            disabled={this.state.disableSync}
                                            onClick={this.syncData}
                                        ><IconSync /></div>
                                }
                            </Tooltip>
                        }
                        <Export
                            data={this.state.data}
                            path={this.props.location.pathname}
                        />
                        {/* <Tooltip title="Filter">
                            <div className="header-button"><IconFilter /></div>
                        </Tooltip> */}
                        <FilterCourses
                            filter={this.state.filter}
                            onFilter={this.filter}
                        />
                        {
                            this.props.userType !== "REPORTER" &&
                                <AddItem
                                    isOpen={this.state.isOpen}
                                    handleToggle={this.handleToggle}
                                    reloadData={this.loadData}
                                    match={this.props.match}
                                    roleName={this.props.roleName}
                                />
                        }
                    </div>
                </div>
                {
                    this.state.filter &&
                    <div className="course-list-container">
                        <div className="filter-tag">
                            {
                                this.state.filter.start &&
                                <Fragment>
                                <span>Ngày bắt đầu:</span>{common.convertToDate(this.state.filter.start)}
                                </Fragment>
                            }
                            {
                                this.state.filter.start && this.state.filter.end &&
                                <span className="separate-token" />
                            }
                            {
                                this.state.filter.end &&
                                <Fragment>
                                <span>Ngày kết thúc:</span>{common.convertToDate(this.state.filter.end)}
                                </Fragment>
                            }
                            <div className="clear-filter" onClick={this.clearFilter}>
                                <IconClose />
                            </div>
                        </div>
                    </div>
                }
                <div className="instructor-body">
                    <div className="instructor-wrap">
                        {
                            this.state.data.length !== 0 &&
                            <SuperTable
                                rowKey="email"
                                unit="Danh sách Customers"
                                data={this.state.data}
                                columns={this.state.columns}
                                pagination={this.state.pagination}
                                onPaginate={this.handlePaginate}
                                rowsActive={this.state.selectedData}
                                sortObj={this.state.sortObj}
                                onSortColumn={this.handleSortData}
                            />
                        }
                        {
                            !this.state.isLoading &&
                            this.state.data.length === 0 &&
                            <div className="container-empty-student">
                                {
                                this.state.search.trim() !== '' ? (
                                    <Fragment>
                                        <IconNotFound />
                                        <div className="label-emtpy-student">
                                            {`Không tìm thấy kết quả nào \nvới từ khoá “${this.state.search}”`}
                                        </div>
                                        <div className="remove-filter" onClick={this.removeFilter}>Xoá bộ lọc</div>
                                    </Fragment>
                                ) : (
                                        <Fragment>
                                            {
                                                this.props.history.location.pathname === constant.ROUTE_CUSTOMER_LIST ?
                                                    <Fragment>
                                                        <IconStudent />
                                                        <div className="label-emtpy-student">Chưa có Khách hàng nào</div>
                                                        {
                                                                this.props.userType !== "REPORTER" &&
                                                                <div className="btn-begin-enroll" onClick={this.startCreateOpen}>
                                                                    <IconAdd />
                                                                    <div>Bắt đầu thêm Khách hàng</div>
                                                                </div>
                                                        }

                                                        <ModalCommon isOpen={this.state.startCreateOpen} toggle={this.startCreateOpen}>
                                                            <CreateCustomer
                                                                handleToggle={this.startCreateOpen}
                                                                reloadData={this.loadData}
                                                            />
                                                        </ModalCommon>
                                                    </Fragment>
                                                    :
                                                    <div className="no-students">
                                                        <IconStudents />
                                                        <div className="label-emtpy-student">Chưa có học viên nào</div>
                                                    </div>
                                            }
                                        </Fragment>
                                    )
                                }
                            </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withRouter(withUserType(Customers));