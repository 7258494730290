import React, { PureComponent } from 'react';

class IconAdd extends PureComponent {
    render() {
        return (
            <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8 0v16M0 8h16"
                    stroke="#fff"
                    strokeWidth={1.5}
                    strokeMiterlimit={10}
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default IconAdd;