import React, {Fragment, PureComponent} from 'react';
import { Row, Col } from 'reactstrap';
import { saleServices } from '../../services/saleServices';
import IconClose from '../Icons/IconClose';
import IconDown from '../Icons/IconDown';
import Select, { components } from 'react-select';
import { toast } from '../../utils/Components/SimpleToast';
import {withRouter} from 'react-router-dom';
import withUserType from '../../HOC/UserTypeHOC';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
        <IconDown/>
        </components.DropdownIndicator>
    );
};
class CreateCustomer extends PureComponent{
    state = {
        email: '',
        fullName: '',
        region: '',
        saleclerkEmail: this.props.email || '',
        saleclerkEmails: [],
        emailError: false,
        fullNameError: false,
        saleclerkEmailError: false,
        isSubmitting: false,
        licenseType: '',
        licenseError: false,
    };


    toggle = () => {
        if(this.props.handleToggle){
            this.props.handleToggle();
        }
    }

    handleInputChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
        this.validateInput(e.target.id, e.target.value);
    }

    validateInput = (field, value) => {
        if(field === void 0 && value === void 0){
            let allowSubmit = false;
            let emailError = this.validateInput('email', this.state.email);
            let fullNameError = this.validateInput('fullName', this.state.fullName);
            let saleclerkEmailError = this.validateInput('saleclerkEmail', this.state.saleclerkEmail);
            let licenseError = this.validateInput('select', this.state.licenseType);

            if(emailError && fullNameError && saleclerkEmailError && licenseError){
                allowSubmit = true;
            }
            return allowSubmit;
        }
        if(field === 'email'){
            if(value === ''){
                this.setState({emailError: "Vui lòng nhập email khách hàng"});
                return false;
            } else if ((/\S+@\S+\.\S+/.test(value)) === false) {
                this.setState({
                    emailError: 'Email không hợp lệ',
                });
                return false;
            }else {
                this.setState({emailError: ""})
            }
        } else if(field ==='fullName'){
            if(value === ''){
                this.setState({fullNameError: "Vui lòng nhập tên"});
                return false;
            } else {
                this.setState({fullNameError: ""});
            }
        } else if(field ==='saleclerkEmail'){
            if(value === ''){
                this.setState({saleclerkEmailError: "Vui lòng chọn email nhân viên"});
                return false;
            } else {
                this.setState({saleclerkEmailError: ""});
            }
        } else if(field ==='select'){
            if(value === ''){
                this.setState({licenseError: "Vui lòng chọn"});
                return false;
            } else {
                this.setState({licenseError: ""});
            }
        }
        return true;
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if(this.validateInput()){
            await this.setState({isSubmitting: true})
            let data = new FormData();
            data.append('email', this.state.email);
            data.append('full_name', this.state.fullName);
            data.append('region_uid', '' );
            data.append('salesclerk_email', this.state.saleclerkEmail );
            data.append('license_type', this.state.licenseType);

            let [success, body] = await saleServices.createSale(data);
            if(success){
                toast('Đã tạo thành công')
                this.toggle();
                setTimeout(() => {
                    if(this.props.history.location.pathname === '/customers'){
                        this.props.reloadData();
                    } else {
                        this.props.history.push('/customers')
                    }
                }, 100);
            }
        }
        this.setState({
            isSubmitting: false
        })
    }

    getOptions = () => {
        let options = [
            {
                value: 'NONE',
                label: <div className='title'>Tạo tài khoản không có license</div>
            },{
                value: 'TRIAL',
                label: <div className='title'>License 14 ngày</div>
            },{
                value: 'NEW',
                label: <div>
                            <div className='title'>Tạo mới</div>
                            <div className='warning'>Cảnh báo</div>
                            <div className='license-text'>Nếu bạn tạo mới license bạn phải chịu trách nhiệm về vấn đề tài chính</div>
                        </div>
            }
        ];
        return options;
    };

    getSaleOptions = async () => {
        let body = {
            "manager_email": this.props.email,
            "is_customer": false,
            "status": "AVAILABLE",
        };
        const [success, data] = await saleServices.getListSales(body);
        let options = [
            {
                value: this.props.email,
                label: <div className='title'>{this.props.email}</div>,
            }
        ];
        if (success) {
            if (data.data && data.data.content) {
                for(let i of data.data.content) {
                    options.push({
                        value: i.email,
                        label: <div className='title'>{i.email}</div>,
                    })
                }
            }
            this.setState({
                saleclerkEmails: options,
            });
        }
    };

    handleSelect = (e) => {
        this.setState({licenseType: e.value},()=>{
            this.validateInput('select', e.value)
            }
        );
    };

    handleSelectSales = (e) => {
        this.setState({saleclerkEmail: e.value},()=>{
            this.validateInput('saleclerkEmail', e.value)
            }
        );
    }

    componentDidMount() {
        this.getSaleOptions();
    }

    render(){
        return(
            <Fragment>
                <div className="common-modal-header">
                    <label>Thêm khách hàng</label>
                    <div className="circle-button" onClick={this.toggle}><IconClose/></div>
                </div>
                <div className="common-modal-body">
                    <Row className="input-row">
                        <Col md={3}>
                            <div className="title-input">Email<span className="ic-start">*</span></div>
                        </Col>
                        <Col md={9}>
                            <input
                                className="field-input"
                                placeholder='Email'
                                id="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                            />
                            {this.state.emailError && <div className="message-error">{this.state.emailError}</div>}
                        </Col>
                    </Row>
                    <Row className="input-row">
                        <Col md={3}>
                            <div className="title-input">Tên<span className="ic-start">*</span></div>
                        </Col>
                        <Col md={9}>
                            <input
                                className="field-input"
                                placeholder='Tên'
                                id="fullName"
                                value={this.state.fullName}
                                onChange={this.handleInputChange}
                            />
                            {this.state.fullNameError && <div className="message-error">{this.state.fullNameError}</div>}
                        </Col>
                    </Row>
                    {/* <Row className="input-row">
                        <Col md={3}>
                            <div className="title-input">Địa điểm</div>
                        </Col>
                        <Col md={9}>
                            <input
                                className="field-input"
                                placeholder='Địa điểm'
                                id="region"
                                value={this.state.region}
                                onChange={this.handleInputChange}
                            />
                        </Col>
                    </Row> */}
                    <Row className="input-row">
                        <Col md={3}>
                            <div className="title-input">Sale<span className="ic-start">*</span></div>
                        </Col>
                        <Col md={9}>
                            <Select
                                defaultValue={{
                                    value: this.props.email,
                                    label: <div className='title'>{this.props.email}</div>,
                                }}
                                onChange={this.handleSelectSales}
                                options={this.state.saleclerkEmails}
                                components={{DropdownIndicator}}
                                className='elite-select'
                                classNamePrefix='elite-sale'
                            />
                            {this.state.saleclerkEmailError && <div className="message-error">{this.state.saleclerkEmailError}</div>}
                        </Col>
                    </Row>
                    <Row className="input-row license-dropdown">
                        <Col md={3}>
                            <div className="title-input">Licenses<span className="ic-start">*</span></div>
                        </Col>
                        <Col md={9}>
                            <Select
                                onChange={this.handleSelect}
                                options = {this.getOptions()}
                                components={{DropdownIndicator}}
                                className='elite-select'
                                classNamePrefix='elite-sale'
                            />
                            {this.state.licenseError && <div className="message-error">{this.state.licenseError}</div>}
                        </Col>
                    </Row>
                    <div className="modal-actions">
                        <button className="btn-cancel" onClick={this.toggle}>Đóng</button>
                        <button className="btn-active bg-green" onClick={this.handleSubmit} disabled={this.state.isSubmitting}>Thêm</button>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default withRouter(withUserType(CreateCustomer));