import React, { PureComponent } from 'react';

class IconSale extends PureComponent {
    render() {
        return (
            <svg
                className="ic-points"
                width={48}
                height={48}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                <path d="M6.44747 13.9648L7.48892 9.10477L7.61851 8.5H7.00001H3.30001C3.04457 8.5 2.76861 8.22995 2.88078 7.83736L4.8666 0.886969C5.00942 0.631234 5.24599 0.5 5.40001 0.5H9.20001C9.45497 0.5 9.73038 0.76903 9.61989 1.16041L8.52075 4.85751L8.32974 5.5H9.00001H12C12.1775 5.5 12.3466 5.61116 12.4403 5.79861C12.5341 5.98614 12.5122 6.1643 12.4159 6.27991L12.4104 6.28652L12.4051 6.29332L6.44747 13.9648Z" 
                fill="#ACACAC" 
                stroke="#ACACAC"
                />
                </g>
            </svg>
        );
    }
}

export default IconSale;