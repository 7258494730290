import React, { PureComponent } from 'react';

class IconAdd extends PureComponent {
    render() {
        return (
            <svg 
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8 12V2"
                    stroke="#5840BB"
                    strokeMiterlimit={10}
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5 6L8 1.5 3.5 6"
                    stroke="#5840BB"
                    strokeMiterlimit={10}
                    strokeLinecap="square"
                    strokeLinejoin="round"
                />
                <path
                    d="M15 14.5H1"
                    stroke="#5840BB"
                    strokeMiterlimit={10}
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default IconAdd;