import React, { PureComponent } from 'react';
import ClonedItem from './Common/ClonedItem';
import TogglerContext from './Common/TogglerContext';

class TogglerItem extends PureComponent {

    render() {
        const { children, delay, ...props } = this.props;
        return (
            <TogglerContext.Consumer>
                { context => {
                return (
                    React.Children.map(children,
                        child => {
                            return <Toggler ref={child.ref || null} child={child} toggle={context.toggle} delay={delay} {...props} />
                            // return React.forwardRef((props, ref) => <TheToggler innerRef={ref} child={child} toggle={context.toggle} {...props} />)
                        }
                    )
                )
                }}
            </TogglerContext.Consumer>
        )
    }
}

class TheToggler extends PureComponent {

    doToggle = () => {
        if (this.props.toggle) {
            setTimeout(() => {
                this.props.toggle();
            }, this.props.delay || 300);
        }
    }

    render() {
        const { child, toggle, innerRef, delay, ...props } = this.props;
        return (
            <ClonedItem ref={innerRef} {...props} child={child} onClick={this.doToggle} />
        );
    }
}

const Toggler = React.forwardRef((props, ref) => <TheToggler
    innerRef={ref} {...props}
/>);

export default TogglerItem;