import React, { PureComponent } from 'react';

class IconSearch extends PureComponent {
    render() {
        return (
            <svg
                className="icon-search"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx={7} cy={7} r="6.5" stroke="white" />
                <path
                d="M15.1464 15.8536C15.3417 16.0488 15.6583 16.0488 15.8536 15.8536C16.0488 15.6583 16.0488 15.3417 15.8536 15.1464L15.1464 15.8536ZM15.8536 15.1464L11.8536 11.1464L11.1464 11.8536L15.1464 15.8536L15.8536 15.1464Z"
                fill="white"
                />
            </svg>
        );
    }
}

export default IconSearch;