import React, { PureComponent } from 'react';

class IconCalendar extends PureComponent {
    render() {
        return (
            <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.5 2V0H4.5V2H2C0.895431 2 0 2.89543 0 4V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V4C16 2.89543 15.1046 2 14 2H11.5V0H10.5V2H5.5ZM1 6.5H15V14C15 14.5523 14.5523 15 14 15H2C1.44772 15 1 14.5523 1 14V6.5ZM1 5.5H15V4C15 3.44772 14.5523 3 14 3H2C1.44772 3 1 3.44772 1 4V5.5Z"
                fill="#797979"
                />
            </svg>
        );
    }
}

export default IconCalendar;