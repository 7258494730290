import React, { PureComponent } from 'react'

export default class IconStudent extends PureComponent {
    render() {
        return (
            <svg
                width={48}
                height={48}
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx={24}
                    cy={10.5}
                    r={8.5}
                    fill="#DBDBDB"
                    stroke="#DBDBDB"
                    strokeWidth={4}
                />
                <rect
                    x={2}
                    y={29}
                    width={44}
                    height={17}
                    rx={8.5}
                    fill="#DBDBDB"
                    stroke="#DBDBDB"
                    strokeWidth={4}
                />
            </svg>
        )
    }
}
