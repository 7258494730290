import React, { PureComponent } from 'react';
import { Modal } from 'reactstrap';

class ModalCommon extends PureComponent {

	toggle = () => {
		if (this.props.toggle) {
			this.props.toggle();
		}
	}

	render() {
		const { isOpen, className } = this.props;

		return (
			<Modal isOpen={isOpen} toggle={this.toggle} modalClassName={`common-modal-container ${className || ''}`} zIndex="1050">
				{this.props.children}
			</Modal>
		)
	}
}

export default ModalCommon;
