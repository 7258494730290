import React, { PureComponent } from 'react';

class IconToggleMenu extends PureComponent {
    render() {
        return (
            <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M16 2H0" stroke="white" strokeWidth="1.5" />
                <path d="M8 8H0" stroke="white" strokeWidth="1.5" />
                <path d="M16 14H0" stroke="white" strokeWidth="1.5" />
            </svg>
        );
    }
}

export default IconToggleMenu;