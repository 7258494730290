import React, { PureComponent } from 'react';

class SingleCheckbox extends PureComponent {
    state = {
        checked: false,
        hovering: false,
    }

    componentDidMount() {
        this.setValue(this.props.checked);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.checked !== this.props.checked && this.props.checked !== this.state.checked) {
            this.setValue(this.props.checked);
        }
    }

    setValue = (checked) => {
        this.setState({
            checked: checked === true,
        });
    }

    setHovering = () => {
        if (this.props.colorActive && !this.state.hovering) {
            this.setState({
                hovering: true,
            });
        }
    }

    removeHovering = () => {
        if (this.props.colorActive && this.state.hovering) {
            this.setState({
                hovering: false,
            });
        }
    }

    handleChange = (e) => {
        let checked = e.target.checked;
        this.setValue(checked);
        if (this.props.onChange) {
            this.props.onChange(checked);
        }
    }

    render() {
        const { id, name, className, label, colorActive, readOnly, disabled, isPartial } = this.props;
        let isChecked = this.state.checked === true;
        return (
            <div id={id || ''}
                className={`${className || ''} single-checkbox`}
            >
                <label className={`cds-checkbox-wrapper ${disabled ? 'disabled' : ''}`}
                onMouseOver={this.setHovering}
                onMouseLeave={this.removeHovering}
                >
                <span className={`cds-checkbox ${isChecked ? 'checked' : `${isPartial ? 'partial' : ''}`}`}>
                    <input type="checkbox" value={name} checked={isChecked} onChange={this.handleChange} disabled={readOnly || disabled} />
                    <span className="cds-checkbox-inner"
                        style={(isChecked && colorActive) ? { borderColor: colorActive, background: colorActive } :
                            (colorActive && this.state.hovering ? { borderColor: colorActive } : {})}
                    >
                        <svg
                            width={12}
                            height={12}
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M2 5.5L5 8.5L10 3.5" stroke="white" strokeLinecap="square" />
                        </svg>
                        <div className="partial-rect" />
                    </span>
                </span>
                <span className="checkbox-label">{label || ''}</span>
                </label>
            </div>
        );
    }
}

export default SingleCheckbox;