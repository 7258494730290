import React, { PureComponent } from 'react';
import _ from 'lodash';
import HeaderColumn from './HeaderColumn';
import SortObjModel from './Models/SortObjModel';
import PaginationModel from './Models/PaginationModel';
import SuperTableRow from './SuperTableRow';
import Pagination from './Pagination';
import { constant } from '../../../services/constant';

const defaultMessageNoData = 'No data.';

class SuperTable extends PureComponent {
    state = {
        data: [],
        pagination: new PaginationModel(),

        sortObj: new SortObjModel(),
        overflowLeft: false,
        overflowRight: false,
    }
    containerRef = null;
    tableRef = null;

    componentDidMount() {
        this.loadDataTable();
        if (this.containerRef && this.props.overflowTable) {
            this.setFixedColumn();
            this.containerRef.addEventListener("scroll", this.setFixedColumn);
        }
    }

    componentWillUnmount() {
        if (this.containerRef && this.props.overflowTable) {
            this.containerRef.removeEventListener("scroll", this.setFixedColumn);
        }
    }

    setFixedColumn = () => {
        if (this.containerRef && this.tableRef) {
            let overflowLeft = false;
            let overflowRight = false;
            if (Math.floor(this.tableRef.getBoundingClientRect().left) < Math.floor(this.containerRef.getBoundingClientRect().left)) {
                overflowLeft = true;
            }
            if (Math.floor(this.tableRef.getBoundingClientRect().right) > Math.floor(this.containerRef.getBoundingClientRect().right)) {
                overflowRight = true;
            }
            if (overflowLeft !== this.state.overflowLeft || overflowRight !== this.state.overflowRight) {
                this.setState({
                overflowLeft: overflowLeft,
                overflowRight: overflowRight,
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data) || !_.isEqual(prevProps.pagination, this.props.pagination) || !_.isEqual(prevProps.sortObj, this.props.sortObj)) {
            this.loadDataTable();
        }
        // if (!_.isEqual(prevProps.data, this.props.data)) {
        //     this.loadDataTable();
        // }
    }

    loadDataTable = () => {
        const { sortObj, pagination, data } = this.props;
        if (sortObj && sortObj instanceof SortObjModel && !_.isEqual(sortObj, this.state.sortObj)) {
            this.setState({ sortObj: _.cloneDeep(sortObj) });
        }
        if (pagination && pagination instanceof PaginationModel && !_.isEqual(pagination, this.state.pagination)) {
            this.setState({
                pagination: _.cloneDeep(pagination),
            });
        }
        if (!_.isEqual(data, this.state.data)) {
            this.setState({ data: _.cloneDeep(this.props.data) });
        }
    }

    handleSortColumn = (sortObj) => {
        this.setState({
            sortObj: _.cloneDeep(sortObj),
        });
        if (this.props.onSortColumn) {
            this.props.onSortColumn(sortObj);
        }
    }

    setContainerRef = (r) => {
        this.containerRef = r;
    }

    setTableRef = (r) => {
        this.tableRef = r;
    }

    render() {
        const { id, rowKey, columns, draggable, messageNoData, unit, rowsActive, overflowTable } = this.props;
        const { data, pagination, overflowLeft, overflowRight } = this.state;
        let hasPagination = pagination.totalRows > constant.DEFAULT_PAGE_SIZE;
        return (
            <div className="super-table-container" style={overflowTable ? { overflow: 'auto hidden', paddingBottom: '16px' } : null} ref={this.setContainerRef}>
                <table className={`super-table ${hasPagination ? '' : 'no-paginate'} ${overflowTable ? 'overflow-table' : ''} ${overflowLeft ? 'table-overflow-left' : ''} ${overflowRight ? 'table-overflow-right' : ''}`} id={id || ''} ref={this.setTableRef}>
                <thead>
                    <tr className="super-table-row-header">
                        {
                            columns.map((col, index) => {
                            let isFixedLeft = col.fixedLeft;
                            let isFixedLeftLast = col.fixedLeft && (index !== (columns.length - 1)) && !columns[index + 1].fixedLeft;
                            let isFixedRight = col.fixedRight;
                            let isFixedRightLast = col.fixedRight && (index !== 0) && !columns[index - 1].fixedRight;
                            return (
                                <th key={`${col}_${index}`}
                                    className={`header-col ${index === 0 ? 'first-col' : ''} ${index === (columns.length - 1) ? 'last-col' : ''} ${col.headerClassName || ''} ${isFixedLeft ? 'col-fixed-left' : ''} ${isFixedLeftLast ? 'col-fixed-left-last' : ''} ${isFixedRight ? 'col-fixed-right' : ''} ${isFixedRightLast ? 'col-fixed-right-last' : ''}`}
                                >
                                    <HeaderColumn
                                        name={col.name}
                                        header={col.header}
                                        sort={col.sort}
                                        sortObj={this.state.sortObj}
                                        onSort={this.handleSortColumn}
                                    />
                                </th>
                            )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, index) => {
                            let isActive = false;
                            if (rowsActive) {
                            isActive = rowsActive.some(r => r === row[rowKey]);
                            }
                            return (
                            <SuperTableRow
                                key={row[rowKey]}
                                rowKey={rowKey}
                                row={row}
                                rowIndex={index}
                                columns={columns}
                                isActive={isActive}
                                draggable={draggable}
                            />
                            )
                        })
                    }
                    {
                        data.length === 0 &&
                        <tr>
                            <td className="super-table-row" colSpan={columns.length}>
                            <div className="super-row-no-data">{messageNoData || defaultMessageNoData}</div>
                            </td>
                        </tr>
                    }
                </tbody>
                </table>
                {
                hasPagination &&
                <Pagination
                    unit={unit || 'Page'}
                    pagination={pagination}
                    onPaginate={this.props.onPaginate}
                />
                }
            </div>
        );
    }
}

export default SuperTable;