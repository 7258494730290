import React, { Fragment, PureComponent } from 'react';
import { Tooltip as RsTooltip } from 'reactstrap';
import ClonedItem from './Common/ClonedItem';

/**
 * placement: one of:
    'top',
    'right',
    'bottom',
    'left',
 */

class Tooltip extends PureComponent {
    state = {
        isOpen: false,
    }
    childRef = null;

    setChildRef = (r) => {
        this.childRef = r;
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    showTooltip = () => {
        if (!this.state.isOpen) {
            this.setState({ isOpen: true });
        }
    }

    render() {
        const { className, placement = 'bottom', title, children, ...props } = this.props;
        return (
            <Fragment>
            {
                React.Children.map(children,
                    child => {
                        return (
                        <ClonedItem
                            {...props}
                            child={child}
                            ref={this.setChildRef}
                            onMouseOver={this.showTooltip}
                        />
                        )
                    }
                )
            }
            {
                this.state.isOpen &&
                <RsTooltip placement={placement}
                    className={`${className || ''} elite-tooltip`}
                    isOpen={this.state.isOpen}
                    autohide={false}
                    target={this.childRef}
                    // trigger="click"
                    toggle={this.toggle}
                >
                    {title}
                </RsTooltip>
            }
            </Fragment>
        )
    }
}

export default Tooltip;