import React, { Component } from 'react'
import { constant } from '../../services/constant';

export default class IconLock extends Component {
    render() {
        const { disabled = false } = this.props;
        return (
            <svg 
                width={16} 
                height={16} 
                viewBox="0 0 16 16" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.5 6V3.5a2.5 2.5 0 015 0V6h-5zm-1 0V3.5a3.5 3.5 0 117 0V6h.5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h.5zm0 1H4a1 1 0 00-1 1v6a1 1 0 001 1h8a1 1 0 001-1V8a1 1 0 00-1-1H4.5z"
                    fill="#65627A"
                />
            </svg>
        )
    }
}
