const API_URL = "https://sales.api.elitelearning.vn/";

const LOGIN_USER = API_URL + 'oauth2/login';
const GET_USER_PROFILE = API_URL + 'users/me';
const CHANGE_PASSWORD = API_URL + 'users/change_password';
const FORGOT_PASSWORD = (email) => {
    return API_URL + 'users/reset_password?email=' + email;
};
const LOGOUT_USER = API_URL + 'oauth2/logout';
const CREATE_SALE = API_URL + 'users/create';
const GET_LIST_USER = API_URL + 'users/matrix';
const GET_LIST_CUSTOMER = API_URL + 'persons/matrix';
const GET_SALE_ROLE = API_URL + 'roles/get_all';
const GET_STUDENTS_LIST = API_URL + 'persons/matrix';
const GET_USER_INFO_BY_EMAIL = (email) => {
    return API_URL + 'persons/get_by_email?email=' + email;
}
const GET_LIST_SERVICES = API_URL + 'services/licenses/matrix';

const LOCK_CUSTOMER = (email) => {
    return API_URL + 'persons/lock?email=' + email;
};
const UN_LOCK_CUSTOMER = (email) => {
    return API_URL + 'persons/unlock?email=' + email;
};
const TRANSFER_CUSTOMER = (emailCustomer) => {
    return API_URL + 'persons/transfer?email=' + emailCustomer;
};
const GET_LICENSE_LIST = API_URL + 'services/licenses/get_by_salesclerk';
const UPDATE_USER = (email) => {
    return API_URL + 'users/update?email=' + email;
}
const EXTEND_LICENSE = API_URL + 'services/licenses/provide';
const UPLOAD_CONTRACT_FILE = API_URL + 'attachments/upload';
const GET_CONTRACT_FILES = API_URL + 'attachments/matrix';
const GET_TEMPLATE_FILE = API_URL + 'attachments/templates/contract';
const DELETE_CONTRACT_FILE = (uid) => {
    return API_URL + 'attachments/delete?uid=' + uid;
}
const SYNC_DATA = API_URL + 'persons/sync';

export const API_CONST = {
    API_URL,
    LOGIN_USER,
    GET_USER_PROFILE,
    CHANGE_PASSWORD,
    FORGOT_PASSWORD,
    LOGOUT_USER,
    CREATE_SALE,
    GET_LIST_USER,
    GET_SALE_ROLE,
    GET_USER_INFO_BY_EMAIL,
    GET_LIST_SERVICES,
    LOCK_CUSTOMER,
    UN_LOCK_CUSTOMER,
    TRANSFER_CUSTOMER,
    GET_LICENSE_LIST,
    UPDATE_USER,
    EXTEND_LICENSE,
    UPLOAD_CONTRACT_FILE,
    GET_CONTRACT_FILES,
    GET_TEMPLATE_FILE,
    DELETE_CONTRACT_FILE,
    GET_STUDENTS_LIST,
    GET_LIST_CUSTOMER,
    SYNC_DATA,
};