import React, {Fragment, PureComponent} from 'react';
import { Row, Col } from 'reactstrap';
import IconClose from '../Icons/IconClose';
import IconDown from '../Icons/IconDown';
import Select, { components } from 'react-select';
import ModalCommon from '../Common/ModalCommon';
import { serviceService } from '../../services/serviceServices';
import { toast } from '../../utils/Components/SimpleToast';
import Licenses from './LicensesOptions';
import SingleCheckbox from '../../utils/Components/SingleCheckbox';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
        <IconDown/>
        </components.DropdownIndicator>
    );
};
export default class UpdateLicens extends PureComponent{
    state = {
        // licenseList: '',
        dateNumber: '',
        isSubmitting: false,
        licenseError: false,
        dateNumberError: false,
        valueSelect: '',
        proDaysNum: null,
        liveDaysNum: null,
        trial: false,
    };

    componentDidMount(){
        // this.getLicenseList();
    }

    getLicenseList = async () => {
        let [success, body] = await serviceService.getLicenseList();
        if(success){
            console.log(body)
            this.setState({licenseList: body.data.services})
        }
    }

    toggle = () => {
        if(this.props.handleToggle){
            this.props.handleToggle();
            this.setState({
                valueSelect: '',
                dateNumber: ''
            }, ()=>{
                this.getLicenseList();
            })
        }
    }

    handleInputChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
        // this.validateInput(e.target.id, e.target.value);
    }

    // validateInput = (field, value) => {
    //     if(field === void 0 && value === void 0){
    //         let allowSubmit = false;
    //         let licenseError = this.validateInput('select', this.state.valueSelect);
    //         let dateNumberError = this.validateInput('dateNumber', this.state.dateNumber)
    //         if(this.state.valueSelect && this.state.valueSelect.licenseType === 'TRIAL'){
    //             if(licenseError && dateNumberError){
    //                 allowSubmit = true;
    //             }
    //         } else {
    //             if(licenseError){
    //                 allowSubmit = true;
    //             }
    //         }
    //         return allowSubmit;
    //     }
    //     if(this.state.valueSelect && this.state.valueSelect.licenseType === 'TRIAL' && field ==='dateNumber'){
    //         if(value === ''){
    //             this.setState({dateNumberError: "Vui lòng nhập tên"});
    //             return false;
    //         } else {
    //             this.setState({dateNumberError: ""});
    //         }
    //     } else if(field ==='select'){
    //         if(value === ''){
    //             this.setState({licenseError: "Vui lòng chọn"});
    //             return false;
    //         } else {
    //             this.setState({licenseError: ""});
    //         }
    //     }
    //     return true;
    // }

    checkSubmit = () => {
        const {valueSelect, proDaysNum, liveDaysNum} = this.state;
        if ((!valueSelect && !proDaysNum && !liveDaysNum) || (valueSelect && proDaysNum && liveDaysNum)
            || (valueSelect && proDaysNum) || (proDaysNum && liveDaysNum) || (valueSelect && liveDaysNum) ) {
            toast.error('Vui lòng chọn 1 trong 3 license');
            return false
        } else {
            return true;
        }


    }

    handleSubmit = async (e) => {
        e.preventDefault();
        if(this.checkSubmit()){
            const { valueSelect, proDaysNum, liveDaysNum } = this.state;
            await this.setState({isSubmitting: true})

            let data = new FormData();

            data.append('author_email', this.props.user);
            data.append('trial', this.state.trial);
            if (valueSelect){
                data.append('license_type', valueSelect.license_type);
                if (valueSelect.service_code) {
                    data.append('service_code', valueSelect.service_code);
                }
                if (valueSelect.term) {
                    data.append('term', valueSelect.term);
                }
            } else if (proDaysNum){
                data.append("license_type", 'TRIAL');
                data.append("service_code", 'Main_Service');
                data.append('term', 'YEARLY');
                data.append("days", proDaysNum);

            } else if (liveDaysNum) {
                data.append("license_type", 'TRIAL');
                data.append("service_code", 'Live');
                data.append('term', 'YEARLY');
                data.append("days", liveDaysNum);
            }

            let [success, body] = await serviceService.extendLicense(data);
            if(success){
                toast('Thêm license thành công')
                this.toggle();
                setTimeout(() => {
                    this.props.reloadData();
                }, 100);
            }
        };
        this.setState({
            isSubmitting: false,
            dateNumber: '',
        });
    };

    getOptions = () => {
        // if(this.state.licenseList.length !== 0){
        //     let licenseList = this.state.licenseList;
        //     let options = [];
        //     for(let i = 0; i<licenseList.length; i++){
        //         let serviceUid = licenseList[i].uid;
        //         let terms = licenseList[i].terms
        //         if(terms.length !==0){
        //             for(let j = 0; j<terms.length; j++){
        //                 let option = {
        //                     value: {licenseType: 'TRIAL',serviceUid: serviceUid, term: terms[j].name},
        //                     label: <div>
        //                         <div className='title'>{terms[j].name}</div>
        //                         <div className='license-text'><span className='license-day'>{terms[j].available} of {terms[j].total} </span>licenses available</div>
        //                     </div>
        //                 }
        //                 options.push(option)
        //             }
        //         }
        //     };
        //     let newLicense = {
        //         value: {licenseType: 'NEW'},
        //         label:  <div>
        //                     <div className='title'>Tạo mới</div>
        //                     <div className='warning'>Cảnh báo</div>
        //                     <div className='license-text'>Nếu bạn tạo mới license bạn phải chịu trách nhiệm về vấn đề tài chính</div>
        //                 </div>
        //     };
        //     options.push(newLicense);
        //     return options;
        // }
        let options = Licenses.map((license) => {
            return (
                {
                    value: license.value,
                    label: license.lable
                }
            )
        });
        return options;
    };

    handleSelect = (e) => {
        this.setState({valueSelect: e.value}, ()=>{
            // this.validateInput('select', e.value)
            })
    };

    handleChangeTrial = () => {
        this.setState({trial: !this.state.trial});
    }

    render(){
        return(
            <Fragment>
                <ModalCommon isOpen={this.props.isOpen} toggle={this.handleToggle}>
                    <div className="common-modal-header">
                        <label>Cập nhật license</label>
                        <div className="circle-button" onClick={this.toggle}><IconClose/></div>
                    </div>
                    <div className="common-modal-body">
                            <Row className="input-row license-dropdown">
                                <Col md={3}>
                                    <div className="title-input">Licenses</div>
                                </Col>
                                <Col md={9}>
                                    <Select
                                        onChange={this.handleSelect}
                                        options = {this.getOptions()}
                                        components={{DropdownIndicator}}
                                        className='elite-select'
                                        classNamePrefix='elite-sale'
                                    />
                                    {this.state.licenseError && <div className="message-error">{this.state.licenseError}</div>}
                                </Col>
                            </Row>
                            {/* {this.state.valueSelect && this.state.valueSelect.licenseType === 'TRIAL' &&
                                <Row className="input-row">
                                <Col md={3}>
                                    <div className="title-input">Số ngày<span className="ic-start">*</span></div>
                                </Col>
                                <Col md={9}>
                                    <input
                                        className="field-input"
                                        placeholder='Số ngày'
                                        id="dateNumber"
                                        value={this.state.dateNumber}
                                        onChange={this.handleInputChange}
                                    />
                                    {this.state.dateNumberError && <div className="message-error">{this.state.dateNumberError}</div>}
                                </Col>
                            </Row>
                            } */}
                            <Row className="input-row">
                                <Col md={3}>
                                <div className="title-input">EliteLearning Pro・1 năm</div>
                                </Col>
                                <Col md={9}>
                                    <input
                                        className="field-input"
                                        placeholder='Số ngày'
                                        id="proDaysNum"
                                        value={this.state.proDaysNum}
                                        onChange={this.handleInputChange}
                                        type='number'
                                    />
                                </Col>
                            </Row>
                            <Row className="input-row">
                                <Col md={3}>
                                <div className="title-input">EliteLearning Live・1 năm</div>
                                </Col>
                                <Col md={9}>
                                    <input
                                        className="field-input"
                                        placeholder='Số ngày'
                                        id="liveDaysNum"
                                        value={this.state.liveDaysNum}
                                        onChange={this.handleInputChange}
                                        type='number'
                                    />
                                </Col>
                            </Row>
                            <Row className="input-row align-items-center">
                                <Col md={3}>
                                    <div className="title-input">Trial</div>
                                </Col>
                                <Col md={9}>
                                    <SingleCheckbox
                                        checked={this.state.trial}
                                        onChange={this.handleChangeTrial}
                                    />
                                </Col>
                            </Row>

                            <div className="modal-actions">
                                <button className="btn-cancel" onClick={this.toggle}>Đóng</button>
                                <button className="btn-active bg-green" onClick={this.handleSubmit} disabled={this.state.isSubmitting}>Thêm</button>
                            </div>
                    </div>
                </ModalCommon>
            </Fragment>
        )
    }
}