import React, { PureComponent } from 'react';
import moment from "moment";
import Dropdown from './Dropdown';
import TogglerItem from './TogglerItem';

const PrevIcon = () => (
    <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8 10L4 6L8 2" stroke="#111111" strokeLinejoin="round" />
    </svg>
)
const PrevIconDouble = () => (
    <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M5 10L1 6L5 2" stroke="#111111" strokeLinejoin="round" />
        <path d="M11 10L7 6L11 2" stroke="#111111" strokeLinejoin="round" />
    </svg>
)

const DAYS_OF_WEEK = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const DAYS_MAP = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS_MAP = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const VIEW_FORMAT = 'DD/MM/YYYY';
const VALUE_FORMAT = 'YYYY-MM-DD';

const oneDay = 60 * 60 * 24 * 1000;

class DatePicker extends PureComponent {
    constructor(props) {
        super(props);
        let year = this.getCurrentYear();
        let month = this.getCurrentMonth();
        this.state = {
            focused: false,
            year,
            month,
            monthDetails: this.getMonthDetails(year, month),
            showDatePicker: false,
            selectedDay: null,
            inputValue: '',
        }
        this.inputRef = null;
        this.inputContainerRef = null;
    }


    componentDidMount() {
        this.loadReceivedValue(this.props.value || '');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.loadReceivedValue(this.props.value || '');
        }
    }

    loadReceivedValue = (dateString) => {
        if (`${dateString}`.trim() === '' || moment(dateString, VALUE_FORMAT, true).isValid()) {
            let selectedDay = null;
            if (`${dateString}`.trim() !== '') {
                selectedDay = moment(dateString, VALUE_FORMAT).toDate();
            }

            if (selectedDay) {
                if (selectedDay.getTime() !== this.state.selectedDay) {
                let year = selectedDay.getFullYear();
                let month = selectedDay.getMonth();
                this.setState({
                    inputValue: moment(dateString, VALUE_FORMAT).format(VIEW_FORMAT),
                    selectedDay: selectedDay.getTime(),
                    year,
                    month,
                    monthDetails: this.getMonthDetails(year, month),
                });
                }
            } else {
                this.setState({
                inputValue: '',
                selectedDay: null,
                });
            }
        }
    }

    showDatePicker = (showDatePicker = true) => {
        this.setState({ showDatePicker })
    }

    closeDatePicker = () => {
        this.showDatePicker(false);
    }

    openDatePicker = (e) => {
        if (!this.props.disabled) {
            this.showDatePicker();
        }
    }

    /**
        *  Core
        */

    getMonthDetails = (year, month) => {
        let firstDay = new Date(year, month).getDay();
        let numberOfDays = this.getNumberOfDays(year, month);
        let monthArray = [];
        let rows = 6;
        let currentDay = null;
        let index = 0;
        let cols = 7;

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                currentDay = this.getDayDetails({
                index,
                numberOfDays,
                firstDay,
                year,
                month
                });
                monthArray.push(currentDay);
                index++;
            }
        }
        return monthArray;
    }

    getDayDetails = (args) => {
        let date = args.index - args.firstDay;
        let day = args.index % 7;
        let prevMonth = args.month - 1;
        let prevYear = args.year;
        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        let prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
        let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
        let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;

        let exactMonth = (args.month + month);
        let exactYear = args.year;
        if (exactMonth < 0) {
            exactMonth = 11;
            exactYear--;
        } else if (exactMonth > 11) {
            exactMonth = 0;
            exactYear++;
        }

        let timestamp = new Date(exactYear, exactMonth, _date).getTime();
        return {
            date: _date,
            day,
            month,
            exactMonth,
            exactYear,
            timestamp,
            dayString: DAYS_MAP[day]
        }
    }

    getNumberOfDays = (year, month) => {
        return 40 - new Date(year, month, 40).getDate();
    }

    getToday = () => {
        return Date.now() - (Date.now() % oneDay) + (new Date().getTimezoneOffset() * 1000 * 60);
    }
    getCurrentYear = () => {
        return new Date().getFullYear();
    }
    getCurrentMonth = () => {
        return new Date().getMonth();
    }

    isCurrentDay = (day) => {
        return day.timestamp === this.getToday();
    }

    isSelectedDay = (day) => {
        return day.timestamp === this.state.selectedDay;
    }

    getMonthStr = (month) => MONTHS_MAP[Math.max(Math.min(11, month), 0)] || 'Month';

    clearValue = () => {
        this.setState({
            inputValue: '',
            selectedDay: null,
        });
        this.onChangeCallback('');
    }

    selectToday = () => {
        let selectedDay = this.getToday();
        let year = this.getCurrentYear();
        let month = this.getCurrentMonth();
        this.setState({
            selectedDay: selectedDay,
            year,
            month,
            monthDetails: this.getMonthDetails(year, month),
            inputValue: this.getViewDateFormatFromTimestamp(selectedDay),
        });
        this.onChangeCallback(moment(selectedDay).format(VALUE_FORMAT));
    }

    onChangeInputValue = (e) => {
        let inputValue = e.target.value;
        let selectedDay = this.state.selectedDay;
        this.setState({
            inputValue,
        });
        if (inputValue.trim() === '') {
            selectedDay = null;
            this.setState({
                selectedDay: null,
            });
            this.onChangeCallback('');

        } else if (moment(inputValue, VIEW_FORMAT, true).isValid()) {
            selectedDay = moment(inputValue, VIEW_FORMAT).toDate();
            let year = selectedDay.getFullYear();
            let month = selectedDay.getMonth();
            this.setState({
                selectedDay: selectedDay.getTime(),
                year,
                month,
                monthDetails: this.getMonthDetails(year, month),
            });
            this.onChangeCallback(moment(selectedDay.getTime()).format(VALUE_FORMAT));
        }
    }

    onBlurInputValue = () => {
        let inputValue = this.state.inputValue;
        if (inputValue.trim() === '') {
            if (inputValue !== '') {
                this.setState({
                inputValue: '',
                });
            }
        } else if (!(moment(inputValue, VIEW_FORMAT, true).isValid())) {
            this.setState({
                inputValue: this.getViewDateFormatFromTimestamp(this.state.selectedDay),
            });
        }
        this.setState({
            focused: false,
        });
        if (this.props.onBlur) {
            this.props.onBlur(moment(this.state.selectedDay).format(VALUE_FORMAT));
        }
    }

    getViewDateFormatFromTimestamp = (timestamp) => {
        if (!timestamp) {
            return '';
        }
        return moment(timestamp).format(VIEW_FORMAT);
    }

    onDateClick = (day) => {
        if (!this.props.disabled && !this.props.readOnly) {
            if (day.month !== 0) {
                this.setState({
                year: day.exactYear,
                month: day.exactMonth,
                monthDetails: this.getMonthDetails(day.exactYear, day.exactMonth),
                });
            }
            this.setState({
                selectedDay: day.timestamp,
                inputValue: this.getViewDateFormatFromTimestamp(day.timestamp),
            }
                , this.closeDatePicker
            );
            this.onChangeCallback(moment(day.timestamp).format(VALUE_FORMAT));
        }
    }

    onChangeCallback = (dateString) => {
        if (this.props.onChange) {
            this.props.onChange(dateString);
        }
    }

    goPrevYear = () => {
        this.setYear(-1);
    }
    goNextYear = () => {
        this.setYear(1);
    }
    setYear = (offset) => {
        let year = this.state.year + offset;
        this.setState({
            year,
            monthDetails: this.getMonthDetails(year, this.state.month),
        });
    }

    setMonth = (offset) => {
        let year = this.state.year;
        let month = this.state.month + offset;
        if (month === -1) {
            month = 11;
            year--;
        } else if (month === 12) {
            month = 0;
            year++;
        }
        this.setState({
            year,
            month,
            monthDetails: this.getMonthDetails(year, month),
        });
    }
    goPrevMonth = () => {
        this.setMonth(-1);
    }
    goNextMonth = () => {
        this.setMonth(1);
    }

    focusInput = () => {
        if (!this.props.disabled) {
            if (!this.props.readOnly) {
                if (this.inputRef) {
                // this.inputRef.focus();
                this.inputRef.select();
                }
            }
            if (!this.state.focused) {
                this.setState({
                focused: true,
                });
            }
        }
    }

    setInputRef = (r) => {
        this.inputRef = r;
    }
    setInputContainerRef = (r) => {
        this.inputContainerRef = r;
    }
    getInputContainerRef = () => {
        return this.inputContainerRef;
    }

    handleFocus = (e) => {
        // e.target.select();
        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    }

    renderCalendar() {
        return (
            <div className="calendar-container">
                <div className="calendar-head">
                {DAYS_OF_WEEK.map((d, i) => <div key={i} className="calendar-head-name">{d}</div>)}
                </div>
                <div className="calendar-body">
                {
                    this.state.monthDetails.map((day, index) => {
                        return (
                            <DayBtn
                            key={`${day.date}_${day.month}`}
                            day={day}
                            isCurrent={this.isCurrentDay(day)}
                            isSelected={this.isSelectedDay(day)}
                            onClick={this.onDateClick}
                            />
                        )
                    })
                }
                </div>
            </div>
        )
    }

    renderPickerContent = () => {
        const { id, name, placeholder, className, dropdownClassName, readOnly, disabled, suffixIcon, isError } = this.props;
        return (
            <div className={`${dropdownClassName || ''} cds-dp-container ${readOnly ? 'readOnly' : ''}`}>
                <div className="cds-dpc-head">
                <div className="dpch-month-year-control">
                    <div className="btn-control-ym" onClick={this.goPrevYear}>
                        <PrevIconDouble />
                    </div>
                    <div className="btn-control-ym" onClick={this.goPrevMonth}>
                        <PrevIcon />
                    </div>
                </div>
                <div className="mdpch-container">
                    <div className="mdpchc-year">{this.state.year}</div>
                    <div className="mdpchc-month">{this.getMonthStr(this.state.month)}</div>
                </div>
                <div className="dpch-month-year-control">
                    <div className="btn-control-ym next" onClick={this.goNextMonth}>
                        <PrevIcon />
                    </div>
                    <div className="btn-control-ym next" onClick={this.goNextYear}>
                        <PrevIconDouble />
                    </div>
                </div>
                </div>
                <div className="cds-dpc-body">
                {this.renderCalendar()}
                </div>
                {
                !readOnly &&
                <div className="cds-dpc-footer">
                    <TogglerItem>
                        <span className="dpcf-action clear" onClick={this.clearValue}>Clear</span>
                    </TogglerItem>
                    <TogglerItem>
                        <span className="dpcf-action now" onClick={this.selectToday}>Today</span>
                    </TogglerItem>
                </div>
                }
            </div>
        )
    }

    render() {
        const { id, name, placeholder, className, dropdownClassName, readOnly, disabled, suffixIcon, isError } = this.props;
        return (
            <div className={`${className || ''} cds-date-picker ${this.state.focused || this.state.showDatePicker ? 'focused' : ''} ${disabled ? 'disabled' : ''}`}>
                <Dropdown className="cds-dp-container-dropdown" content={this.renderPickerContent()} placement="bottom-end">
                <div className={`cds-dp-input ${isError ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
                    ref={this.setInputContainerRef}
                    onClick={this.openDatePicker}
                    onFocus={this.focusInput} tabIndex="1"
                >
                    <input type="text" value={this.state.inputValue}
                        id={id}
                        name={name}
                        ref={this.setInputRef}
                        placeholder={placeholder || VIEW_FORMAT}
                        readOnly={readOnly}
                        disabled={disabled}
                        onChange={this.onChangeInputValue}
                        onBlur={this.onBlurInputValue}
                        onFocus={this.handleFocus}
                    />
                    <span className="dp-suffix">
                        {
                            suffixIcon ? suffixIcon : (
                            <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M2 6.5H14" stroke="#AAAAAA" />
                                <path d="M5 1L5 3" stroke="#AAAAAA" />
                                <path d="M11 1L11 3" stroke="#AAAAAA" />
                                <rect x="1.5" y="3.5" width={13} height={11} rx="1.5" stroke="#AAAAAA" />
                            </svg>
                            )
                        }
                    </span>
                </div>
                </Dropdown>
            </div>
        )
    }
}

class DayBtn extends PureComponent {
    onDayClick = (e) => {
        this.props.onClick(this.props.day);
    }

    render() {
        const { day, isCurrent, isSelected } = this.props;
        return (
            <TogglerItem>
                <div className={`calendar-day-container ${day.month !== 0 ? 'disabled' : ''} ${isCurrent ? 'current' : ''} ${isSelected ? 'selected' : ''}`}
                onClick={this.onDayClick}
                >
                <div className="calendar-day">{day.date}</div>
                </div>
            </TogglerItem>
        );
    }
}

export default DatePicker;