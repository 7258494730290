import React, { PureComponent } from 'react';

class IconClose extends PureComponent {
    render() {
        return (
            <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M15 1L1 15"
                stroke="#797979"
                strokeWidth="1.5"
                strokeMiterlimit={10}
                strokeLinejoin="round"
                />
                <path
                d="M1 1L15 15"
                stroke="#797979"
                strokeWidth="1.5"
                strokeMiterlimit={10}
                strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default IconClose;