import React, { PureComponent } from 'react'

export default class IconEmail extends PureComponent {
    render() {
        return (
            <svg
                className="ic-email"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.75"
                    y="1.75"
                    width="14.5"
                    height="12.5"
                    rx="1.25"
                    stroke="white"
                    strokeWidth="1.5"
                />
                <path
                    d="M1 2L8 9L15 2"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                />
            </svg>
        )
    }
}
